// General
import styled, { css } from 'styled-components';
import { Address, useAccount, useBalance, useContractWrite, usePrepareContractWrite } from 'wagmi';
import { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
// Types
import { IContractInfo, TStatus } from './index';
// Utils
import CometsABI from '../../../utils/abi/comets_abi.json';
import useToast from '../../../hooks/useToast';
import { Notifications } from '../../layout/app/Layout';
import { dispatchConnectWallet } from '../../../utils/walletConnect/event';
// Components
import { AppHeadline } from '../Container';
import { Button } from '../../common/Button';
// Styles
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import TermsModal from '../TermsModal';
import { Translations } from '../../../utils/Translations';
import { useWalletApi } from '../../../services/wallet';
import { useIsApiReady } from '../../../services/ApiProvider';

const Headline = styled(AppHeadline)`
  margin-block-end: 0.5rem;

  ${BREAKPOINT_MD} {
    margin-block-end: 0.75rem;
  }
`;

const SubHeadline = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
  margin-block-end: 3rem;
  font-family: var(--font-family-default);

  ${BREAKPOINT_XL} {
    margin-block-end: 4rem;
  }
`;

const InformationWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-block-end: 3rem;

  ${BREAKPOINT_XL} {
    margin-block-end: 5rem;
  }
`;

const Information = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InformationTitle = styled.p`
  text-transform: uppercase;
  font-weight: 700;
`;

const Price = styled.span`
  font-family: var(--font-family-headline);
  font-weight: 700;
  font-size: 1.25rem;

  ${BREAKPOINT_MD} {
    font-size: 1.5rem;
  }

  ${BREAKPOINT_LG} {
    font-size: 1.875rem;
  }
`;

const Counter = styled.div`
  display: flex;
  gap: 0.375rem;
`;

const CounterButton = styled(Button)`
  inline-size: 2.5rem;
  block-size: 2.5rem;
  padding: 0;
  text-align: center;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 1;
  border: 1px solid #fff;
`;

const Amount = styled.p<{ disabled: boolean }>`
  inline-size: 2.5rem;
  block-size: 2.5rem;
  display: grid;
  place-content: center;
  font-weight: 700;
  font-family: var(--font-family-headline);
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #fff;
  border-radius: 4px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`;

const Error = styled.p`
  color: #e37663;
  font-size: 1rem;
`;

const MintButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

interface MintViewProps {
  contractInfo?: IContractInfo;
  setStatus: Dispatch<SetStateAction<TStatus>>;
  status: TStatus;
}

export const ClaimView: FC<MintViewProps> = (props) => {
  const { contractInfo, setStatus, status } = props;

  // Get the users account data
  const { address, isConnected } = useAccount();
  const result = useBalance({ address });
  const walletApi = useWalletApi();
  const isApiReady = useIsApiReady();

  // Globabl State
  const { addTransactionToast, addErrorToast } = useToast();
  const notifications = useContext(Notifications);

  // Component State
  const [price, setPrice] = useState<string>('0');
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [allowedQuantity, setAllowedQuantity] = useState<number>();
  const [availableQuantity, setAvailableQuantity] = useState<number>();
  const [proof, setProof] = useState<string[]>([]);
  const [amount, setAmount] = useState<number>(1);

  const balance = useMemo(() => {
    return result.data ? parseFloat(result.data!.formatted).toFixed(3) : 0;
  }, [result.data]);

  // Prepare a contract transaction

  const { config: whitelistClaim } = usePrepareContractWrite({
    address: process.env.REACT_APP_COMETS_CONTRACT_ADDRESS as Address,
    abi: CometsABI,
    functionName: 'claim',
    args: [amount, allowedQuantity, proof],
    enabled: Boolean(status === 'whitelist' && availableQuantity! > 0),
  });

  // Create a contract transaction
  const { data: txId, write, isSuccess } = useContractWrite(whitelistClaim);

  const initWhitelistInformation = useCallback(async () => {
    if (isApiReady) {
      try {
        const { proof, allowedQuantity, mintableQuantity } = await walletApi.getMerkleProofByWallet(address!);
        setAvailableQuantity(mintableQuantity);
        setAllowedQuantity(allowedQuantity);
        setProof(proof);
      } catch (e: any) {
        if (e.response.status === 404) {
          setStatus('notWhitelisted');
        } else {
          addErrorToast(notifications!.internalServerError);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, notifications, isApiReady]);

  const increase = () => {
    setAmount(Math.min(amount + 1, availableQuantity || 1));
  };

  const decrease = () => {
    setAmount(Math.max(amount - 1, 1));
  };

  // Initialize Content
  useEffect(() => {
    if (isConnected && address && isApiReady) {
      initWhitelistInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected, contractInfo, status, isApiReady]);

  // Set allowed quantity on successful mint submission
  useEffect(() => {
    if (isSuccess) {
      setAvailableQuantity(availableQuantity! - amount);
      setStatus('success');
    }
  }, [isSuccess, availableQuantity, amount, setStatus]);

  // Set tx hash on successful mint submission
  useEffect(() => {
    txId &&
      addTransactionToast({
        txHash: txId.hash,
        transactionType: 'claim',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txId]);

  return (
    <>
      <Headline>CLAIM YOUR COMETS</Headline>
      <SubHeadline>{contractInfo?.totalMinted} Claimed</SubHeadline>

      <InformationWrapper>
        <Information>
          <InformationTitle>Your Balance</InformationTitle>
          <Price>{balance} ETH</Price>
        </Information>

        <Information>
          <InformationTitle>Quantity</InformationTitle>
          <Counter>
            <CounterButton theme='secondary' onClick={decrease} disabled={amount === 1}>
              -
            </CounterButton>
            <Amount disabled={!availableQuantity}>{amount}</Amount>
            <CounterButton theme='secondary' onClick={increase} disabled={amount >= (availableQuantity || 1)}>
              +
            </CounterButton>
          </Counter>
        </Information>

        {(availableQuantity || 0) < 1 && <Error>You&apos;ve already claimed the maximum eligible amount.</Error>}
      </InformationWrapper>

      {isConnected ? (
        <MintButton theme='tertiary' disabled={!write || availableQuantity! < 1} onClick={() => setShowTerms(true)}>
          Claim
        </MintButton>
      ) : (
        <MintButton theme='tertiary' onClick={dispatchConnectWallet}>
          Connect Wallet
        </MintButton>
      )}

      {showTerms && (
        <TermsModal
          close={() => setShowTerms(false)}
          content={Translations.claim.termsAndConditions}
          onAccept={() => write?.()}
          acceptText={'Claim'}
        />
      )}
    </>
  );
};
