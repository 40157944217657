import { INFT } from '../../types/NFT';
import { useApi } from '../ApiProvider';

const BASE_PATH = '/contracts/comets';

export const useCometContractApi = () => {
  const apiInstance = useApi();
  const getCometsContractStatus = async () => {
    return (await apiInstance.get<{ status: number }>(`${BASE_PATH}/status`)).data.status;
  };

  const getCometsMinted = async () => {
    return (await apiInstance.get<{ totalMinted: number }>(`${BASE_PATH}/minted`)).data.totalMinted;
  };

  const getCometsNFTs = async (walletAddress: string) => {
    return (await apiInstance.get<{ nfts: Array<INFT> }>(`${BASE_PATH}/nfts/${walletAddress}`)).data.nfts;
  };

  const getAvailableQuantityByWallet = async (walletAddress: string) => {
    return (await apiInstance.get<{ quantity: number }>(`${BASE_PATH}/quantity/${walletAddress}`)).data.quantity;
  };


  return {
    getCometsContractStatus,
    getCometsMinted,
    getCometsNFTs,
    getAvailableQuantityByWallet,
  };

};
