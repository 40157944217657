// General
import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

// Components
import LinkHandler from './LinkHandler';

// Styles
import { BREAKPOINT_LG } from '../../styles/Breakpoints';

const StyledButton = styled.button<{ primary?: boolean; href?: string; theme?: string }>`
  ${({ theme }) => {
    switch (theme) {
      case 'primary':
        return `
          --background: var(--color-grey-300);
          --border-color: var(--color-grey-300);
          --text-color: var(--color-black-900);
        `;
      case 'secondary':
      default:
        return `
          --background: var(--color-black-900);
          --border-color: var(--color-grey-300);
          --text-color: var(--color-grey-300);
        `;
    }
  }}

  border: 1px solid var(--border-color);
  background: var(--background);
  color: var(--text-color);
  --spinner-color: var(--text-color);

  display: flex;
  gap: 0.5rem;
  height: 2.75rem;
  width: fit-content;
  align-items: center;
  padding-inline: 1.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  border-radius: 0.625rem;
  transition-property: box-shadow, border, background, color, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  text-decoration: none;
  white-space: nowrap;

  ${BREAKPOINT_LG} {
    font-size: 1rem;
  }

  @media (hover: hover) {
    &:hover,
    &:active {
      color: var(--color-primary);
      --spinner-color: var(--color-primary);
      border: 1px solid var(--color-primary);
      background: var(--color-red-900);
      box-shadow:
        0 0 2px 0 var(--color-primary),
        0 3px 20px 0 rgba(255, 53, 25, 0.6);
    }
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    box-shadow: none;
    opacity: 0.3;
  }
`;

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'primary' | 'secondary';
  href?: string;
  target?: string;
}

export const Button: FC<PropsWithChildren<IButtonProps>> = (props) => {
  const { children, ...buttonProps } = props;

  return (
    <StyledButton {...(buttonProps as any)} as={buttonProps.href ? LinkHandler : undefined}>
      {children}
    </StyledButton>
  );
};
