import {
  CardAttribute,
  CardAttributes,
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  CardImageWrapper,
  CardOpenSeaLink,
  CardTitle,
  CardWrapper,
  InnerCardWrapper,
} from './CardStyles';
import { ILink } from '../../../types/link';
import OpenSeaIcon from '../../icons/OpenSeaIcon';
import Mooncourt from '../../icons/Mooncourt';
import { Translations } from '../../../utils/Translations';

interface ICometCardProps {
  id: number;
  title: string;
  image: string;
  openseaLink: ILink;
}

export default function CometCard(props: ICometCardProps) {
  const { id, title, image, openseaLink } = props;

  return (
    <CardContainer>
      <CardWrapper hasHover={false}>
        <InnerCardWrapper>
          <CardImageWrapper itemAvailable={true}>
            <video src={image} muted autoPlay loop playsInline />
          </CardImageWrapper>

          <CardBody>
            <CardHeader>
              <CardTitle>{title}</CardTitle>
            </CardHeader>

            <CardAttributes>
              <CardAttribute>
                <p>{Translations.cards.attributes.id}</p>
                <p>#{id}</p>
              </CardAttribute>
            </CardAttributes>

            <CardFooter>
              <CardOpenSeaLink {...openseaLink} to={openseaLink.href}>
                <OpenSeaIcon />
              </CardOpenSeaLink>

              <Mooncourt />
            </CardFooter>
          </CardBody>
        </InnerCardWrapper>
      </CardWrapper>
    </CardContainer>
  );
}
