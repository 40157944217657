// General
import { FC, useContext } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import styled from 'styled-components';

// Helpers
import { dispatchWalletDisconnectedWallet } from '../../../utils/walletConnect/event';
import { WalletConnectTranslations } from '../app/Layout';

// Components
import WalletConnectModal, { IModalProps } from './WalletConnectModal';

// Styles
import { Headline, IntroText, WalletButton } from './ConnectWallet';

const WalletAddress = styled.p`
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: clamp(8px, 3vw, 12px);
  font-family: var(--font-family-default);
  color: #707070;
`;

const ButtonWrapper = styled.div`
  display: grid;
  justify-items: center;
`;

const DisconnectWallet: FC<IModalProps> = ({ close }) => {
  const t = useContext(WalletConnectTranslations);
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const disconnectWallet = () => {
    disconnect();
    close();
    dispatchWalletDisconnectedWallet();
  };

  return (
    <WalletConnectModal close={close}>
      <Headline>{t?.disconnectHeadline}</Headline>

      <WalletAddress>
        {t?.yourWalletAddress}: {address}
      </WalletAddress>

      <IntroText content={t?.disconnectText!} />

      <ButtonWrapper>
        <WalletButton theme={'secondary'} onClick={disconnectWallet}>
          {t?.disconnectButton}
        </WalletButton>
      </ButtonWrapper>
    </WalletConnectModal>
  );
};

export default DisconnectWallet;
