import { useApi } from './ApiProvider';

interface WalletMerkleProofProps {
  proof: string[];
  allowedQuantity: number;
  mintableQuantity: number;
  address: string;
}

export const useWalletApi = () => {
  const apiInstance = useApi();

  const getMerkleProofByWallet = async (walletAddress: string) => {
    return (await apiInstance.get<WalletMerkleProofProps>(`/wallet/${walletAddress}/proof`)).data;
  };


  return {
    getMerkleProofByWallet,
  };
};
