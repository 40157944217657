import { AxiosResponse } from 'axios';
import { INFT } from '../../types/NFT';
import { useApi } from '../ApiProvider';

const BASE_PATH = '/contracts/ballers';

interface BallerNFTsResponseProps {
  nfts: {
    name: string;
    image: string;
    description: string;
    attributes: any[];
  }[];
}

export const useBallerContractApi = () => {
  const apiInstance = useApi();
  const getBallersContractStatus = async () => {
    return (await apiInstance.get<{ status: number }>(`${BASE_PATH}/status`)).data.status;
  };

  const getBallerNFTs = async (walletAddress: string) => {
    return (await apiInstance.get<BallerNFTsResponseProps>(`${BASE_PATH}/nfts/${walletAddress}`)).data.nfts;
  };

  const getAllDownloadableNFTs = async (walletAddress: string) => {
    return (await apiInstance.get<{
      tokens: { id: string; images: string[] }[]
    }>(`${BASE_PATH}/downloads/${walletAddress}`)).data.tokens;
  };

  const getBallerMetadata = async (id: string) => {
    return (await apiInstance.post<Array<number>, AxiosResponse<Array<INFT>>>(`${BASE_PATH}/metadata`, { ids: [id] })).data;
  };

  const getBallerIds = async (wallet: string) => {
    return (await apiInstance.get(`${BASE_PATH}/tokens/${wallet}`)).data.tokenIds;
  };

  return {
    getBallersContractStatus,
    getBallerNFTs,
    getAllDownloadableNFTs,
    getBallerMetadata,
    getBallerIds,
  };
};
