import React, { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface ILoginReturnPageProps {
  type: 'GOOGLE' | 'APPLE' | 'DISCORD';
  urlAuthCodeParam: string;
}

export const LoginReturnPage: FC<ILoginReturnPageProps> = ({ type, urlAuthCodeParam }: ILoginReturnPageProps) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      const code = searchParams.get(urlAuthCodeParam);
     //  console.log('code =>',code);
      window.opener.postMessage({ type: 'sso-auth-code', code, ssoType: type });
      window.close();
    }
  }, [searchParams]);

  return <></>;
};
