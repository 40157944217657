// General
import { SVGProps } from 'react';

export default function Fortnite(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 8 20' {...props}>
      <path fill='currentColor' d='M0 0h8l-.44361 4.35317H4.30075V7.5547h2.82707v4.261H4.35338v7.3705L0 20V0Z' />
    </svg>
  );
}
