import { SVGProps } from 'react';

export default function Mooncourt(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 320 256' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M289.598 199.066L305.422 256H246.732L230.946 199.066L261.112 90.8302H225.2L187.278 226.69H128.393L166.312 90.8302H131.011L93.0921 226.69H61.0164L52.7674 256H0L15.9389 199.389H41.8244L72.1295 90.8302L37.9291 0H97.1575L110.927 34.2228H299.673L320 90.8302L289.598 199.066Z'
        fill='currentColor'
      />
    </svg>
  );
}
