type TOpenSeaUrl = 'baller' | 'booster' | 'comet' | 'shop' | 'raffle';

export default function getOpenSeaUrl(type: TOpenSeaUrl, id: number) {

  switch (type) {
    case 'baller':
      return `https://opensea.io/assets/ethereum/${process.env.REACT_APP_BALLER_CONTRACT_ADDRESS}/${id}`;
    case 'booster':
      return `https://opensea.io/assets/ethereum/${process.env.REACT_APP_BOOSTER_CONTRACT_ADDRESS}/${id}`;
    case 'comet':
      return `https://opensea.io/assets/ethereum/${process.env.REACT_APP_COMETS_CONTRACT_ADDRESS}/${id}`;
    case 'shop':
      return `https://opensea.io/assets/ethereum/${process.env.REACT_APP_SHOP_CONTRACT_ADDRESS}/${id}`;
    case 'raffle':
      return `https://opensea.io/assets/ethereum/${process.env.REACT_APP_SHOP_CONTRACT_ADDRESS}/${id}`;
  }
}
