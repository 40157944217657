import { useEffect, useState } from 'react';

const useCountdown = (targetDate: string) => {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(countDownDate - +new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - +new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getCountdownTimes(countDown);
};

const format = (count: number) => {
  return `00${count.toFixed(0)}`.substr(-2);
};

const getDays = (diff: number) => {
  return Math.floor(diff / (1000 * 60 * 60 * 24));
};

const getHours = (diff: number) => {
  return Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
};

const getMinutes = (diff: number) => {
  return Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
};

const getSeconds = (diff: number) => {
  return Math.floor((diff % (1000 * 60)) / 1000);
};

const getCountdownTimes = (countDown: number) => {
  return [format(getDays(countDown)), format(getHours(countDown)), format(getMinutes(countDown)), format(getSeconds(countDown))];
};

export { useCountdown };
