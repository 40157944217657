import { SVGProps } from 'react';

export default function AlertIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.99372 0.302734C10.5056 0.302734 10.9777 0.56544 11.2373 0.996278L19.0229 13.8864C19.2861 14.3207 19.2861 14.8566 19.0302 15.291C18.7742 15.7253 18.2948 15.995 17.7794 15.995H2.20804C1.6926 15.995 1.2132 15.7253 0.957285 15.291C0.701367 14.8566 0.704972 14.3172 0.964494 13.8864L8.75017 0.996278C9.00969 0.56544 9.48188 0.302734 9.99372 0.302734ZM9.99372 4.78625C9.51432 4.78625 9.12864 5.16104 9.12864 5.62691V9.54999C9.12864 10.0159 9.51432 10.3906 9.99372 10.3906C10.4731 10.3906 10.8588 10.0159 10.8588 9.54999V5.62691C10.8588 5.16104 10.4731 4.78625 9.99372 4.78625ZM11.1472 12.6324C11.1472 12.3351 11.0256 12.05 10.8093 11.8398C10.593 11.6296 10.2996 11.5115 9.99372 11.5115C9.68781 11.5115 9.39443 11.6296 9.17812 11.8398C8.96181 12.05 8.84028 12.3351 8.84028 12.6324C8.84028 12.9297 8.96181 13.2148 9.17812 13.425C9.39443 13.6352 9.68781 13.7533 9.99372 13.7533C10.2996 13.7533 10.593 13.6352 10.8093 13.425C11.0256 13.2148 11.1472 12.9297 11.1472 12.6324Z'
        fill='currentColor'
      />
    </svg>
  );
}