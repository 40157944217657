// General
import styled from 'styled-components';

// Types
import { INFT } from '../../../types/NFT';
import { IBallerRarity } from '../../../services/rarities';

// Utils
import { getBallerId } from '../../../utils/getNFTId';

// Icons
import PointsIcon from '../../icons/PointsIcon';
import Mooncourt from '../../icons/Mooncourt';

// Styles
import {
  CardAttribute,
  CardAttributes,
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  CardId,
  CardImageWrapper,
  CardPrice,
  CardTitle,
  CardWrapper,
  InnerCardWrapper,
} from './CardStyles';

// Components
import LinkHandler from '../../common/LinkHandler';
import { Translations } from '../../../utils/Translations';

const Wrapper = styled(CardWrapper)<{ rarity: string }>`
  box-shadow: var(--box-shadow-rarity-${({ rarity }) => rarity});
`;

const MooncourtIcon = styled(Mooncourt)<{ rarity: string }>`
  color: var(--color-rarity-${({ rarity }) => rarity});
`;

export interface IRarityCardProps extends INFT, Partial<IBallerRarity> {
  points?: number;
  loadLazy?: boolean;
}

export default function RarityCard(props: IRarityCardProps) {
  const { name, image, points, loadLazy, rarity, ballerClass, dailyPoints, boost } = props;

  return (
    <CardContainer>
      <Wrapper rarity={rarity || ''} as={LinkHandler} to={`/ballers/${name.split('#')[1]}`}>
        <InnerCardWrapper>
          <CardImageWrapper itemAvailable={true}>
            <img
              src={image.replace(`${getBallerId(name)}.png`, `600x600/${getBallerId(name)}.png`)}
              alt={name}
              loading={loadLazy ? 'lazy' : undefined}
            />
          </CardImageWrapper>

          <CardBody>
            <CardHeader>
              <CardTitle hasShadow>{ballerClass}</CardTitle>

              <CardId>#{name.split('#')[1]}</CardId>
            </CardHeader>

            <CardAttributes>
              <CardAttribute>
                <p>{Translations.cards.attributes.collection}</p>
                <p>baller</p>
              </CardAttribute>

              <CardAttribute>
                <p>{Translations.cards.attributes.rarity}</p>
                <p>{rarity}</p>
              </CardAttribute>

              <CardAttribute>
                <p>{Translations.cards.attributes.dailyPoints}</p>
                <p>{dailyPoints} MP</p>
              </CardAttribute>

              <CardAttribute lowerCase>
                <p>{Translations.cards.attributes.boost}</p>
                <p>x{boost}</p>
              </CardAttribute>
            </CardAttributes>

            <CardFooter>
              <CardPrice>
                {points && (
                  <>
                    <PointsIcon />
                    {points} MP
                  </>
                )}
              </CardPrice>

              <MooncourtIcon rarity={rarity || ''} />
            </CardFooter>
          </CardBody>
        </InnerCardWrapper>
      </Wrapper>
    </CardContainer>
  );
}
