import { INFT } from '../../types/NFT';
import { useApi } from '../ApiProvider';

const BASE_PATH = '/contracts/booster';

export const useBoosterContractApi = () => {
  const apiInstance = useApi();
  const getBoosterContractStatus = async () => {
    return (await apiInstance.get<{ status: number }>(`${BASE_PATH}/status`)).data.status;
  };

  const getBoosterSupply = async () => {
    return (await apiInstance.get<{ supply: number }>(`${BASE_PATH}/supply`)).data.supply;
  };

  const getBatchSupply = async () => {
    return (await apiInstance.get<{ batchSupply: number }>(`${BASE_PATH}/supply/batch`)).data.batchSupply;
  };

  const getBoostersMinted = async () => {
    return (await apiInstance.get<{ totalMinted: number }>(`${BASE_PATH}/minted`)).data.totalMinted;
  };

  const getBoosterMaxSupply = async () => {
    return (await apiInstance.get<{ maxSupply: number }>(`${BASE_PATH}/supply/max`)).data.maxSupply;
  };

  const getBoosterNFTs = async (walletAddress: string) => {
    return (await apiInstance.get<{ nfts: Array<INFT> }>(`${BASE_PATH}/nfts/${walletAddress}`)).data.nfts;
  };

  const getBoosterPrice = async () => {
    return (await apiInstance.get<{ price: string }>(`${BASE_PATH}/price`)).data.price.toString();
  };

  const getAvailableQuantity = async (walletAddress: string) => {
    return (await apiInstance.get<{ quantity: number }>(`${BASE_PATH}/quantity/public/${walletAddress}`)).data.quantity;
  };

  return {
    getBoosterContractStatus,
    getBoosterSupply,
    getBatchSupply,
    getBoostersMinted,
    getBoosterMaxSupply,
    getBoosterNFTs,
    getBoosterPrice,
    getAvailableQuantity,
  }
}
