// General
import { FC, SVGProps } from "react"

const Close: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11C4.90524 7.09476 7.09475 4.90524 11 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M1 1C4.90524 4.90524 7.09475 7.09475 11 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);

export default Close;
