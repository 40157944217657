import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { LocalStorageEffect } from './effects/LocalStorageEffect';

interface ICookieState {
  showConsent: boolean;
  allowedCookies: string[];
}

const CookieState = atom<ICookieState>({
  key: 'cookie-state',
  default: {
    showConsent: true,
    allowedCookies: [],
  },
  effects: [
    LocalStorageEffect('cookies'),
  ],
});

export const useAllowedCookies = () => useRecoilValue(CookieState).allowedCookies;
export const useCookieState = () => useRecoilState(CookieState);