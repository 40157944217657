import { HTMLAttributes, PropsWithChildren, useMemo } from 'react';
import { ToastContentProps } from 'react-toastify';
import styled from 'styled-components';
import Wysiwyg from '../../common/Wysiwyg';
import Close from '../../icons/CloseIcon';
import { TypeOptions } from 'react-toastify/dist/types';
import InfoIcon from '../../icons/InfoIcon';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
import AlertIcon from '../../icons/AlertIcon';

const ToastWrapper = styled.div`
  padding: 0.5rem 0.75rem;
  color: var(--color-black);
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  width: 100%;
`;

const CloseIcon = styled(Close)`
  font-size: 1.25rem;
  margin-top: 0.25rem;
  cursor: pointer;
`;

const Content = styled.div`
  grid-area: 2 / 2;
  font-size: 1rem;
  flex: 1;
`;

export const ToastIconWrapper = styled.div`
  font-size: 1.5rem;
  line-height: 1;
  height: 2.25rem;
  width: 2.25rem;
  display: grid;
  place-content: center;
`;

export const ToastHeadline = styled.p`
  font-size: 1.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 700;
  font-family: var(--font-family-headline);

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const ToastContent = styled(Wysiwyg)`
  margin-bottom: 0;

  a {
    font-weight: 700;
    text-decoration: underline;
  }
`;

interface IToastProps extends PropsWithChildren<Partial<ToastContentProps<HTMLAttributes<HTMLDivElement>>>> {
  type: TypeOptions;
}

/**
 * Basic component wrapper used by error & transaction toasts.
 * Provides general styling as well as closing overrides for the toasts.
 */
export default function Toast(props: IToastProps) {
  const { children, closeToast, type } = props;

  const ToastIconByType = useMemo(() => {
    switch (type) {
      case 'success':
        return <CheckmarkIcon style={{ color: 'var(--color-signal-green)', fontSize: '1.125rem' }} />;
      case 'warning':
        return <AlertIcon style={{ color: 'var(--color-signal-yellow)' }} />;
      case 'error':
        return <AlertIcon style={{ color: 'var(--color-signal-red)' }} />;
      case 'info':
      case 'default':
      default:
        return <InfoIcon style={{ color: 'var(--color-black)' }} />;
    }
  }, [type]);

  return (
    <ToastWrapper>
      <ToastIconWrapper>{ToastIconByType}</ToastIconWrapper>

      <Content>{children}</Content>

      <CloseIcon type='button' aria-label='Close' onClick={closeToast} />
    </ToastWrapper>
  );
}
