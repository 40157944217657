import styled from 'styled-components';
import { ITrainingsToken } from '../../../types/stake';
import { CardBody, CardContainer, CardHeader, CardImageWrapper, CardTitle, CardWrapper, InnerCardWrapper } from './CardStyles';
import PointsIcon from '../../icons/PointsIcon';
import RocketIcon from '../../icons/RocketIcon';

const StyledCardContainer = styled(CardContainer)`
  display: grid;
`;

const TrainingsInfo = styled.div<{ rarity: string }>`
  align-self: flex-start;
  z-index: 2;
  display: flex;
  justify-content: space-evenly;
  border: 1px solid var(--color-rarity-${({ rarity = 'common' }) => rarity});
  border-radius: 3rem;
  background: var(--base-grey-900, #1a1a1a);
  box-shadow: 0 0 8px 0 var(--box-shadow-rarity-${({ rarity = 'common' }) => rarity}),
    0 0 2px 0 var(--box-shadow-rarity-${({ rarity = 'common' }) => rarity});
  margin-block-end: -12cqw;
  gap: 1rem;
  padding-block: 2.5cqw;
  margin-inline: 6.5cqw;
  padding-inline: 7.75cqw;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 8cqw;
  font-weight: 700;

  svg {
    font-size: 12cqw;
  }
`;

const StyledCardTitle = styled(CardTitle)`
  font-size: 12cqw;
`;

interface ITrainingsCardProps extends ITrainingsToken {
  //
}

export default function TrainingsCard(props: ITrainingsCardProps) {
  const { id, rarity, images, points, boost, staked } = props;

  return (
    <StyledCardContainer>
      <TrainingsInfo rarity={rarity}>
        {staked ? (
          <>
            <IconWrapper>
              <PointsIcon /> {points}
            </IconWrapper>

            <IconWrapper>
              <RocketIcon /> x{boost}
            </IconWrapper>
          </>
        ) : (
          <IconWrapper>Not Trained</IconWrapper>
        )}
      </TrainingsInfo>

      <CardWrapper rarity={rarity} hasHover={false}>
        <InnerCardWrapper>
          <CardImageWrapper>
            <img src={images.small} alt={`Baller ${id}`} />
          </CardImageWrapper>

          <CardBody>
            <CardHeader>
              <StyledCardTitle>{props.class}</StyledCardTitle>
            </CardHeader>
          </CardBody>
        </InnerCardWrapper>
      </CardWrapper>
    </StyledCardContainer>
  );
}
