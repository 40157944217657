import { SVGProps } from 'react';

export default function LinkedInIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.45898 21.875H1.92383V7.27051H6.45898V21.875ZM4.18896 5.27832C2.73877 5.27832 1.5625 4.07715 1.5625 2.62695C1.5625 1.93037 1.83922 1.26232 2.33177 0.769762C2.82433 0.277204 3.49238 0.000488281 4.18896 0.000488281C4.88555 0.000488281 5.5536 0.277204 6.04616 0.769762C6.53871 1.26232 6.81543 1.93037 6.81543 2.62695C6.81543 4.07715 5.63867 5.27832 4.18896 5.27832ZM23.4326 21.875H18.9072V14.7656C18.9072 13.0713 18.873 10.8984 16.5493 10.8984C14.1914 10.8984 13.8301 12.7393 13.8301 14.6436V21.875H9.29981V7.27051H13.6494V9.2627H13.7129C14.3184 8.11523 15.7974 6.9043 18.0039 6.9043C22.5938 6.9043 23.4375 9.92676 23.4375 13.8525V21.875H23.4326Z'
        fill='currentColor'
      />
    </svg>
  );
}
