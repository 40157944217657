import { INews } from '../../../types/news';
import styled from 'styled-components';
import { BREAKPOINT_MD, BREAKPOINT_XXL, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import LinkHandler from '../../common/LinkHandler';

const Button = styled.button`
  text-decoration: underline;
  font-family: var(--font-family-headline);
  font-weight: 700;
  white-space: nowrap;
  grid-area: 2 / 2;
  align-self: flex-end;

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary);
    }
  }
`;

const Wrapper = styled(LinkHandler)`
  display: grid;

  ${BREAKPOINT_XXL} {
    height: 100%;
  }

  &:hover,
  &:active {
    text-decoration: none;

    ${Button} {
      color: var(--color-primary);
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  grid-area: 1 / 1;
  display: flex;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 12.09%, rgba(0, 0, 0, 0) 44.54%);
  }
`;

const Image = styled.img`
  width: 100%;
  //box-shadow: 10px 0 10px 0 rgba(0, 0, 0, 0.8);
  aspect-ratio: 1 / 1;

  ${BREAKPOINT_MD} {
    aspect-ratio: 2 / 1;
  }

  ${BREAKPOINT_XXL} {
    aspect-ratio: auto;
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    z-index: 1;
    display: grid;
    grid-area: 1 / 1;
    align-self: flex-end;
    grid-template-columns: 1fr min-content;
    padding: 1.25rem 1rem;
    column-gap: 2.5rem;
  }
`;

const Title = styled.h5`
  font-size: 1.875rem;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  margin-block-end: 0.5rem;
  grid-area: 1 / 1 / 1 / 3;
  align-self: flex-end;
`;

const PreviewText = styled.p`
  line-height: 1.3;
  grid-area: 2 / 1;
  font-size: 0.875rem;
`;

interface INewsSliderCard extends INews {
  readMoreLabel: string;
}

export default function NewsSliderCard(props: INewsSliderCard) {
  const { id, title, previewText, image, link, readMoreLabel } = props;

  return (
    <Wrapper to={link?.href || `/news/${id}`}>
      <ImageWrapper>
        <picture>
          <source media={`(min-width:${DIMEN_BREAKPOINT_MD}px)`} srcSet={image.sizes.previewDesktop.url} />
          <Image src={image.sizes.previewMobile.url} alt={image.alt} />
        </picture>
      </ImageWrapper>

      <Content>
        <Title>{title}</Title>

        <PreviewText>{previewText}</PreviewText>

        <Button>{link?.title || readMoreLabel}</Button>
      </Content>
    </Wrapper>
  );
}
