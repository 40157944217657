// General
import { FC, SVGProps } from 'react';

const Wallet: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.9375 0H0V0.964286V17.0357V18H0.9375H19.0625H20V17.0357V4.82143V3.85714H19.0625H4.6875H3.75V5.78571H4.6875H18.125V16.0714H1.875V1.92857H17.8125H18.75V0H17.8125H0.9375ZM15 12.2143C15.3315 12.2143 15.6495 12.0788 15.8839 11.8377C16.1183 11.5966 16.25 11.2696 16.25 10.9286C16.25 10.5876 16.1183 10.2606 15.8839 10.0194C15.6495 9.77832 15.3315 9.64286 15 9.64286C14.6685 9.64286 14.3505 9.77832 14.1161 10.0194C13.8817 10.2606 13.75 10.5876 13.75 10.9286C13.75 11.2696 13.8817 11.5966 14.1161 11.8377C14.3505 12.0788 14.6685 12.2143 15 12.2143Z'
      fill='currentColor'
    />
  </svg>
);

export default Wallet;