import styled from 'styled-components';
import { DetailPageSubHeadline } from './DetailPageStyledComponents';
import Select from '../../../common/form/Select';
import { ShopItemVariant } from '../../../../services/shop';
import { useMemo } from 'react';
import { Translations } from '../../../../utils/Translations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-block: 2rem;

  select {
    text-transform: capitalize;
  }
`;

const HeadlineWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

const Amount = styled.h6`
  font-size: 1.125rem;
  line-height: 1;
  font-weight: 700;
`;

interface IDetailPageVariantSelectProps {
  variants: ShopItemVariant[];
  disabled?: boolean;
  availableAmount?: number;
}

export default function DetailPageVariantSelect(props: IDetailPageVariantSelectProps) {
  const { variants, disabled, availableAmount } = props;

  const formattedVariants = useMemo(() => {
    return variants
      .map((variant) => ({
        name:
          variant.attributes.map((attr) => attr.type + ': ' + attr.value).join(' | ') +
          (availableAmount ? ` - (${variant.availableAmount} left)` : ''),
        value: variant.id,
        disabled: variant.availableAmount === 0 || variant.amount === 0,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [variants, availableAmount]);

  return (
    <Wrapper>
      <HeadlineWrapper>
        <DetailPageSubHeadline>Variants</DetailPageSubHeadline>
        {availableAmount && <Amount>{Translations.detailPage.availableAmount + ': ' + availableAmount}</Amount>}
      </HeadlineWrapper>

      <Select name={'variant'} items={formattedVariants} disabled={disabled} placeholder={'select size'} />
    </Wrapper>
  );
}
