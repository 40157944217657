// General
import { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const LinkHandler: FC<PropsWithChildren<LinkProps & React.RefAttributes<HTMLAnchorElement>>> = (props) => {
  const { to, children, ...aProps } = props;

  if (typeof to === 'string') {
    if (to.startsWith('http')) {
      return (
        <a {...aProps} target={'_blank'} rel={'noreferrer noopener'} href={to}>
          {children}
        </a>
      );
    }
  }

  return (
    <Link to={to} {...aProps}>
      {children}
    </Link>
  );
};

export default LinkHandler;
