// General
import { useEffect } from 'react';

// Helpers
import { useInitGA } from '../../hooks/useGA';

export const GoogleAnalytics = () => {
  const initGA = useInitGA();

  useEffect(() => {
    initGA(true); // enable google analytics

    return () => {
      initGA(false); // disable google analytics
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
