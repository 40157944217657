// General
import { FC, SVGProps } from 'react';

const RefreshIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16' {...props}>
    <path
      fill='currentColor'
      d='M14.6101 6.7254h1.0822V.00087564H13.528V2.91484l-.5952-.61642c-2.95908-3.064561-7.75445-3.064561-10.71351 0-2.959055 3.06456-2.959055 8.03088 0 11.09548 2.95906 3.0645 7.75443 3.0645 10.71351 0l-1.532-1.5866c-2.11358 2.189-5.53933 2.189-7.65294 0-2.11361-2.18895-2.11361-5.73684 0-7.92581 2.11361-2.18898 5.53936-2.18898 7.65294 0l.5817.6024H9.19929V6.7254h5.41081Z'
    />
  </svg>
);

export default RefreshIcon;
