import styled, { CSSProperties } from 'styled-components';
import { ITrainingStatus } from '../../../types/stake';
import { TabSwitchBody, TabSwitchHeadline, TabSwitchInnerContainer } from './TrainingTabSwitch';
import { useMemo } from 'react';
import StatTile from '../dashboard/StatTile';
import { BREAKPOINT_MD, BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import TrainingsCard from '../cards/TrainingsCard';
import Tooltip, { AbsoluteWrapper } from '../../common/Tooltip';
import { Translations } from '../../../utils/Translations';

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const PowerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledTooltip = styled(Tooltip)`
  position: initial;
  
  ${AbsoluteWrapper} {
    top: calc(100% + 1rem);
    left: 0;
    width: 100%;
    max-width: 32rem;
    z-index: 10;
  }
`;

const PowerHeadline = styled.h5`
  display: flex;
  gap: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const PowerIndicatorWrapper = styled.div`
  display: grid;
  align-items: center;
  width: 100%;

  ::before {
    content: '';
    grid-area: 1 / 1;
    width: 100%;
    height: 1rem;
    border-radius: 1rem;
    border: 1px solid var(--color-signal-green);
    background: #152722;
    box-shadow: 0 0 20px 0 var(--color-signal-green), 0 0 5px 0 var(--color-signal-green);
  }

  ::after {
    content: '';
    grid-area: 1 / 1;
    height: 1rem;
    width: calc(var(--progress) + 1px);
    background: linear-gradient(90deg, #0c6c4f 0%, #10e4a4 100%, #0c6c4f 0%);
    border-radius: 1rem;
    transition: width 0.25s ease-in-out;
  }
`;

const PowerIndicator = styled.span`
  grid-area: 1 / 1;
  display: grid;
  place-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  font-size: 0.75rem;
  font-weight: 700;
  background: #152722;
  box-shadow: 0 0 14px 0 var(--color-signal-green), 0 0 2px 0 var(--color-signal-green);
  transform: translateX(-2px);
`;

const StatTileWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const CustomStatTile = styled(StatTile)`
  ${BREAKPOINT_MD} {
    width: 100%;
  }
`;

const WideCustomStatTile = styled(CustomStatTile)`
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 1rem;
  text-align: start;

  > :first-child {
    grid-row: 1 / 3;
    text-align: end;
  }
`;

const TeamWrapper = styled.div`
  display: grid;
  column-gap: 0.75rem;
  row-gap: 1.75rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

  ${BREAKPOINT_XXL} {
    max-height: calc(100vh - var(--nav-height) - (var(--content-spacing) / 2) - 36px - 1.25rem - 52px - 1.25rem - 8rem - 36px - 1.75rem);
    margin-inline-end: -1rem;
    padding-inline-end: 1rem;
    overflow: auto;
  }
`;

interface ITrainingsTabProps {
  trainingStatus?: ITrainingStatus;
}

export default function TrainingsTab(props: ITrainingsTabProps) {
  const { trainingStatus } = props;

  const power = useMemo(() => Math.round((trainingStatus?.power || 0) * 100), [trainingStatus]);

  return (
    <TabSwitchBody>
      <TabSwitchInnerContainer>
        <TabSwitchHeadline>team stats</TabSwitchHeadline>

        <StatsWrapper>
          <PowerWrapper>
            <TooltipWrapper>
              <PowerHeadline>power</PowerHeadline>
              <StyledTooltip translation={Translations.tooltip.power} />
            </TooltipWrapper>

            <PowerIndicatorWrapper style={{ '--progress': `${power}%` } as CSSProperties}>
              <PowerIndicator>{power}%</PowerIndicator>
            </PowerIndicatorWrapper>
          </PowerWrapper>

          <StatTileWrapper>
            <CustomStatTile value={trainingStatus?.ballerPoints || 0} name={'baller mp'} />
            <CustomStatTile value={trainingStatus?.boost || 1} name={'boost'} />
            <WideCustomStatTile value={trainingStatus?.dailyPoints || 0} name={'moonpoints per day'} label={'Power x Baller MP x Boost'} />
          </StatTileWrapper>
        </StatsWrapper>
      </TabSwitchInnerContainer>

      <TabSwitchInnerContainer>
        <TabSwitchHeadline>team ({trainingStatus?.tokens.length || 0})</TabSwitchHeadline>

        <TeamWrapper>
          {trainingStatus?.tokens.map((item) => (
            <TrainingsCard key={item.id} {...item} />
          ))}
        </TeamWrapper>
      </TabSwitchInnerContainer>
    </TabSwitchBody>
  );
}
