// General
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
// Utils
import useToast from '../../../hooks/useToast';
import { Notifications } from '../../layout/app/Layout';
// Components
import { ClaimStatusSection, StatusSectionProps } from './ClaimStatusSection';
import { ClaimView } from './ClaimView';
// Styles
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import LoadingAnimation from '../../common/LoadingAnimation';
import { useCometContractApi } from '../../../services/contract/comet';
import { useIsApiReady } from '../../../services/ApiProvider';

export const StyledContainer = styled.div`
  display: grid;
  align-items: center;
  align-self: center;
  background: linear-gradient(180deg, #2c2b2b 0, rgba(32, 32, 32, 0.51) 100%);
  border: 1px solid #adadad;
  border-radius: 10px;
  gap: 2rem;
  grid-template-columns: auto;
  margin: 0 auto;
  padding: 1rem;
  max-width: 600px;

  ${BREAKPOINT_MD} {
    padding: 2rem;
  }

  ${BREAKPOINT_LG} {
    max-width: unset;
    padding: 0;
    gap: 5rem;
    margin: 0;
    grid-template-columns: 1fr minmax(50%, 1fr);
  }

  ${BREAKPOINT_XL} {
    grid-template-columns: 1fr 600px;
  }
`;

const Content = styled.div`
  display: grid;
  min-height: 12.5rem;

  ${BREAKPOINT_LG} {
    padding-block: 2.5rem;
    padding-inline-end: 2.5rem;
  }

  ${BREAKPOINT_XL} {
    padding-inline-end: 5rem;
  }
`;

const Booster = styled.video`
  border-radius: 10px;

  ${BREAKPOINT_LG} {
    margin-block: 0.625rem;
    margin-inline-start: 0.625rem;
    inline-size: min(600px, 100%);
  }
`;

const StyledLoadingAnimation = styled(LoadingAnimation)`
  font-size: 2rem;
  place-self: center;

  ${BREAKPOINT_MD} {
    font-size: 3rem;
  }
`;

export interface IContractInfo {
  totalMinted: number;
}

export type TStatus = 'whitelist' | 'success' | 'soldOut' | 'paused' | 'notWhitelisted';

export interface MintProps {
  headline: string;
  mintLabel: string;
  termsAndConditions: string;
  successView: StatusSectionProps;
  pausedView: StatusSectionProps;
  soldOutView: StatusSectionProps;
  notWhitelistedView: StatusSectionProps;
}

export const Claim: FC = () => {
  // Component State
  const [contractInfo, setContractInfo] = useState<IContractInfo>();
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<TStatus>('paused');
  // Utils
  const { addErrorToast } = useToast();
  const notifications = useContext(Notifications);
  const cometApi = useCometContractApi();
  const isApiReady = useIsApiReady();

  const getStatus = (status: number) => {
    switch (status) {
      case 2:
        return 'soldOut';
      case 0:
        return 'whitelist';
      case 1:
      default:
        return 'paused';
    }
  };

  const init = useCallback(async () => {
    if (isApiReady) {
      try {
        setLoading(true);
        const [status, totalMinted] = await Promise.all([cometApi.getCometsContractStatus(), cometApi.getCometsMinted()]);
        setStatus(getStatus(status));
        setContractInfo({ totalMinted });
      } catch {
        addErrorToast(notifications!.internalServerError);
      } finally {
        setLoading(false);
      }
    }
  }, [isApiReady]);

  useEffect(() => {
    if (isApiReady) {
      init();
    }
  }, [isApiReady]);

  const statusView = useMemo(() => {
    switch (status) {
      case 'whitelist':
        return <ClaimView contractInfo={contractInfo} setStatus={setStatus} status={status} />;
      case 'success':
        return (
          <ClaimStatusSection
            {...{
              headline: 'Thanks for claiming',
              text: 'While your transaction is being processed feel free to look around the on rest of our website.',
              link: {
                title: 'Home',
                href: '/',
                target: '_self',
              },
            }}
          />
        );
      case 'soldOut':
        return (
          <ClaimStatusSection
            {...{
              headline: 'We are sold out',
              text: 'Thank you to everyone who participated in the claim. We got a bright future ahead of us.',
              link: {
                title: 'Opensea',
                href: 'https://opensea.io/collection/mooncourt-baller',
                target: '_blank',
              },
            }}
          />
        );
      case 'paused':
        return (
          <ClaimStatusSection
            {...{
              headline: "We aren't ready yet!",
              text: 'Seems like you are a little bit too early. Check back later to claim your comets. Meanwhile feel free to look around at the rest of our website',
              link: {
                title: 'Home',
                href: '/',
                target: '_self',
              },
            }}
          />
        );
      case 'notWhitelisted':
        return (
          <ClaimStatusSection
            {...{
              headline: 'We are sorry!',
              text: "Seems like you didn't make it on our whitelist. Feel free to check out the comets collection on Opensea to be part of our exclusive community.",
              link: {
                title: 'Opensea',
                href: 'https://opensea.io/collection/mooncourt-baller',
                target: '_blank',
              },
            }}
          />
        );
    }
  }, [status, contractInfo]);

  return (
    <StyledContainer>
      <Booster aria-hidden={true} muted loop playsInline autoPlay>
        <source type='video/webm' src='/images/comet.webm' />
        <source type='video/mp4' src='/images/comet.mp4' />
      </Booster>
      <Content>{loading ? <StyledLoadingAnimation /> : statusView}</Content>
    </StyledContainer>
  );
};
