// General
import { FC, SVGProps } from 'react';

const ChevronIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M13 5.5L7.78261 10.5L3 5.5' stroke='currentColor' strokeWidth='.625' strokeLinecap='round' />
  </svg>
);

export default ChevronIcon;
