import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { LocalStorageEffect } from './effects/LocalStorageEffect';

type TTxHash = `0x${string}`;
export type TTransactionType = 'mint' | 'reveal' | 'claim';

interface ITxn {
  txHash: TTxHash;
  transactionType: TTransactionType;
  tokenIds?: Array<number>;
  hidden?: false;
}

// Only set when there is a pending mint transaction to show a loading toast
const TransactionState = atom<Array<ITxn>>({
  key: 'transaction-state',
  default: [],
  effects: [LocalStorageEffect('transaction')],
});

export const useTransactionState = () => {
  const [txs, setTxs] = useRecoilState(TransactionState);

  const addTx = useCallback(
    (txHash: TTxHash, transactionType: TTransactionType, tokenIds: Array<number> = []) => {
      setTxs([...txs, { txHash, transactionType, tokenIds }]);
    },
    [txs, setTxs],
  );

  const removeTx = useCallback(
    (txHash: TTxHash) => {
      const index = txs.findIndex(({ txHash: _txHash }) => txHash === _txHash);
      const _errors = [...txs];
      _errors.splice(index, 1);
      setTxs(_errors);
    },
    [txs, setTxs],
  );

  return {
    addTx,
    removeTx,
  };
};

export default TransactionState;
