/**
 * Custom event dispatcher function
 * @param name Name of the custom even
 * @param data Event detail information that's being passed along side the event
 */
const dispatchCustomEvent = (name: string, data?: any) => {
  const event = new CustomEvent(name, { detail: data });
  window.dispatchEvent(event);
};

/**
 * Dispatch a WALLET_VERIFIED event on the window with the signed message as content
 * @param signedMessage Message returned by the wallet signature process
 */
export const dispatchWalletVerifiedEvent = () => {
  dispatchCustomEvent('WALLET_VERIFIED');
}

/**
 * Dispatch a WALLET_VERIFICATION_FAILED event on the window with error as content
 * @param err Error thrown by the failed wallet verification
 */
export const dispatchWalletVerificationFailedEvent = (err: any) => {
  dispatchCustomEvent('WALLET_VERIFICATION_FAILED', err);
}

/**
 * Dispatch a WALLET_CONNECTED event on the window with the wallet as content
 * @param walletAddress Address of the wallet that got connected
 */
export const dispatchWalletConnectedEvent = (walletAddress: string) => {
  dispatchCustomEvent('WALLET_CONNECTED', walletAddress);
}

/**
 * Dispatch a CONNECT_WALLET event on the window to trigger the connect wallet modal
 * @param walletAddress Address of the wallet that got connected
 */
export const dispatchConnectWallet = () => {
  dispatchCustomEvent('CONNECT_WALLET');
}

/**
 * Dispatch a DISCONNECT_WALLET event on the window to trigger the disconnect wallet modal
 */
 export const dispatchDisconnectWallet = () => {
  dispatchCustomEvent('DISCONNECT_WALLET');
}

/**
 * Dispatch a WALLET_DISCONNECTED event on the window
 */
 export const dispatchWalletDisconnectedWallet = () => {
  dispatchCustomEvent('WALLET_DISCONNECTED');
}

/**
 * Dispatch a WALLET_VERIFICATION_FAILED event on the window with error as content
 * @param err Error thrown by the failed wallet verification
 */
 export const dispatchWalletConnectingFailedEvent = (err: any) => {
  dispatchCustomEvent('WALLET_CONNECTING_FAILED', err);
}