import {
  CardAttribute,
  CardAttributes,
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  CardImageWrapper,
  CardPrice,
  CardTitle,
  InnerCardWrapper,
  ShopCardWrapper,
} from './CardStyles';
import Mooncourt from '../../icons/Mooncourt';
import { ShopHistoryItemProps, ShopItemProps } from '../../../services/shop';
import { useMemo } from 'react';
import styled from 'styled-components';
import PointsIcon from '../../icons/PointsIcon';
import LinkHandler from '../../common/LinkHandler';
import CardCountdown from './CardCountdown';
import { Translations } from '../../../utils/Translations';

const StyledInnerCardWrapper = styled(InnerCardWrapper)`
  background: var(--color-text-default);
  color: var(--color-text-highlight);
`;

interface IShopItemCardProps extends Partial<ShopHistoryItemProps> {
  loadLazy?: boolean;
  item: ShopItemProps;
}

export default function ShopItemCard(props: IShopItemCardProps) {
  const { id, item, loadLazy, purchasedAt, redeemedAt, itemVariant } = props;
  const { name, price, previewImage, variants, category, startDate, endDate } = item;

  const availableAmount = useMemo(() => {
    if (variants.length) {
      let amount = 0;

      for (let i = 0; i < variants.length; i++) {
        amount = amount + variants[i]?.availableAmount;
      }

      return amount;
    } else {
      return 0;
    }
  }, [variants]);

  const ownedVariant = useMemo(() => variants.find((item) => item.id === itemVariant), [itemVariant, variants]);

  const isAvailable = useMemo(() => {
    if (availableAmount === 0 ) {
      return false;
    }

    if (endDate && new Date(endDate).getTime() < new Date().getTime()) {
      return false;
    }

    return true;
  }, [availableAmount, endDate]);


  return (
    <CardContainer>
      <ShopCardWrapper as={LinkHandler} to={`/shop/items/${item.id}`}>
        <StyledInnerCardWrapper>
          <CardImageWrapper hasGradient={false} itemAvailable={isAvailable}>
            <img src={previewImage?.url} alt={name} loading={loadLazy ? 'lazy' : undefined} />
          </CardImageWrapper>

          {!id && <CardCountdown startDate={startDate} endDate={endDate} placeholder={availableAmount === 0 ? 'Sold Out' : undefined} />}

          <CardBody>
            <CardHeader>
              <CardTitle>{name}</CardTitle>
            </CardHeader>

            <CardAttributes>
              <CardAttribute>
                <p>{Translations.cards.attributes.level}</p>
                <p>{category.level}</p>
              </CardAttribute>
              {purchasedAt ? (
                <>
                  {ownedVariant &&
                    ownedVariant.attributes.map(({ value, type }, index) => (
                      <CardAttribute key={index}>
                        <p>{type}</p>
                        <p>{value}</p>
                      </CardAttribute>
                    ))}

                  <CardAttribute>
                    <p>{Translations.cards.attributes.purchasedAt}</p>
                    <p>{new Date(purchasedAt).toLocaleDateString()}</p>
                  </CardAttribute>

                  <CardAttribute>
                    <p>{Translations.cards.attributes.processedAt}</p>
                    <p>{redeemedAt ? new Date(redeemedAt).toLocaleDateString() : '-'}</p>
                  </CardAttribute>
                </>
              ) : (
                <CardAttribute>
                  <p>{Translations.cards.attributes.quantity}</p>
                  <p>{availableAmount || '-'}</p>
                </CardAttribute>
              )}
            </CardAttributes>

            <CardFooter>
              <CardPrice>
                <PointsIcon />
                {price} MP
              </CardPrice>

              <Mooncourt />
            </CardFooter>
          </CardBody>
        </StyledInnerCardWrapper>
      </ShopCardWrapper>
    </CardContainer>
  );
}
