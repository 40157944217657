import {
  CardAttribute,
  CardAttributes,
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  CardImageWrapper,
  CardLabel,
  CardTitle,
  CardWrapper,
  InnerCardWrapper,
} from './CardStyles';
import Mooncourt from '../../icons/Mooncourt';
import styled from 'styled-components';
import { Translations } from '../../../utils/Translations';

const StyledCardWrapper = styled(CardWrapper)`
  border: none;
  background: linear-gradient(128deg, #313131 10.5%, #1c1c1c 52.84%, #313131 82.44%);
  box-shadow: 3px 3px 3px 0 rgba(255, 255, 255, 0.4) inset, -3px -3px 3px 0 rgba(0, 0, 0, 0.4) inset;
`;

const StyledCardLabel = styled(CardLabel)`
  color: var(--color-text-highlight);
  background: var(--color-text-default);
`;

export interface IComingSoonItem {
  comingSoon: boolean;
  reveal: string;
}

export default function ComingSoonCard(props: IComingSoonItem) {
  const { reveal } = props;

  return (
    <CardContainer>
      <StyledCardWrapper hasHover={false}>
        <InnerCardWrapper>
          <CardImageWrapper>
            <img src={'/images/ComingSoonPlaceholder.jpg'} alt={'Coming Soon Placeholder'} />
          </CardImageWrapper>

          <CardBody>
            <StyledCardLabel>{Translations.cards.comingSoon.label}</StyledCardLabel>

            <CardHeader>
              <CardTitle>{Translations.cards.comingSoon.title}</CardTitle>
            </CardHeader>

            <CardAttributes>
              <CardAttribute>
                <p>{Translations.cards.attributes.revealAt}</p>
                <p>{new Date(reveal).toLocaleDateString()}</p>
              </CardAttribute>
            </CardAttributes>

            <CardFooter>
              <span />

              <Mooncourt />
            </CardFooter>
          </CardBody>
        </InnerCardWrapper>
      </StyledCardWrapper>
    </CardContainer>
  );
}
