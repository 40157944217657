import { HTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';
import TrainingIcon from '../icons/TrainingIcon';

const Bounce = keyframes`
  0%, 100% {
      transform: translateY(0) scaleY(.85);
  }
  50% {
      animation-timing-function: cubic-bezier(1, 0.01, 0.76, 0.72);
      transform: translateY(-2em);
  }
`;

const Scale = keyframes`
  0%, 100% {
      transform: scaleX(1);
  }
  50% {
      animation-timing-function: cubic-bezier(1, 0.01, 0.76, 0.72);
      transform: scaleX(1.375);
  }
`;

const Wrapper = styled.div`
  --animation-duration: 2s;
  --entry-timing-function: cubic-bezier(0.16, 0.96, 1, 1);
  width: fit-content;
  display: grid;
  justify-items: center;

  &::after {
    content: '';
    display: block;
    margin-block-start: -0.125em;
    width: 1.375em;
    height: 0.5em;
    background: radial-gradient(50% 50% at 50% 50%, #0c0c0c 0%, rgba(0, 0, 0, 0) 100%);
    animation: ${Scale} 2s var(--animation-duration) var(--entry-timing-function) infinite;
  }
`;

const Ball = styled(TrainingIcon)`
  width: 1em;
  height: 1em;
  animation: ${Bounce} var(--animation-duration) var(--entry-timing-function) infinite;
`;

export default function LoadingAnimation(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <Wrapper {...props}>
      <Ball />
    </Wrapper>
  );
}
