// General
import { FC, SVGProps } from "react"

const DiscordIcon: FC<SVGProps<SVGSVGElement>>  = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 16' {...props}>
    <g>
      <path fill='currentColor'
            d='M16.93,1.33C15.66,.71,14.29,.26,12.86,0c-.03,0-.05,0-.07,.03-.18,.33-.37,.76-.51,1.09-1.54-.24-3.07-.24-4.57,0-.14-.34-.34-.76-.51-1.09-.01-.02-.04-.04-.07-.03-1.43,.26-2.8,.71-4.07,1.33-.01,0-.02,.01-.03,.02C.44,5.42-.27,9.38,.08,13.3c0,.02,.01,.04,.03,.05,1.71,1.32,3.37,2.12,4.99,2.65,.03,0,.05,0,.07-.02,.38-.55,.73-1.13,1.02-1.74,.02-.04,0-.08-.03-.09-.54-.22-1.06-.48-1.56-.78-.04-.02-.04-.08,0-.11,.1-.08,.21-.17,.31-.25,.02-.02,.04-.02,.06,0,3.27,1.57,6.82,1.57,10.05,0,.02-.01,.05,0,.07,0,.1,.09,.2,.17,.31,.26,.04,.03,.03,.09,0,.11-.5,.31-1.02,.56-1.56,.78-.04,.01-.05,.06-.03,.09,.3,.61,.64,1.19,1.02,1.74,.02,.02,.04,.03,.07,.03,1.63-.53,3.29-1.33,5-2.65,.01-.01,.02-.03,.03-.05,.42-4.53-.7-8.46-2.96-11.95,0-.01-.01-.02-.03-.02ZM6.68,10.91c-.99,0-1.8-.95-1.8-2.12s.8-2.12,1.8-2.12,1.81,.96,1.8,2.12c0,1.17-.8,2.12-1.8,2.12Zm6.65,0c-.99,0-1.8-.95-1.8-2.12s.8-2.12,1.8-2.12,1.81,.96,1.8,2.12c0,1.17-.79,2.12-1.8,2.12Z' />
    </g>
  </svg>
);

export default DiscordIcon;