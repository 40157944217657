import styled, { css } from 'styled-components';
import { Children, PropsWithChildren } from 'react';
import 'swiper/css';
import { BREAKPOINT_LG, BREAKPOINT_SM, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import { InternalStyledButton } from '../Container';
import { Translations } from '../../../utils/Translations';

const Wrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block-end: 0.5rem;
  border-block-end: 1px solid var(--color-text-default);
`;

const Headline = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;

  ${BREAKPOINT_LG} {
    font-size: 2rem;
  }
`;

const AccordionButton = styled(InternalStyledButton)<{ items: number }>`
  display: ${({ items }) => (items > 1 ? 'initial' : 'none')};

  ${BREAKPOINT_SM} {
    display: ${({ items }) => (items > 2 ? 'initial' : 'none')};
  }

  ${BREAKPOINT_LG} {
    display: ${({ items }) => (items > 3 ? 'initial' : 'none')};
  }

  ${BREAKPOINT_XL} {
    display: ${({ items }) => (items > 4 ? 'initial' : 'none')};
  }
`;

const OverflowWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  container-type: inline-size;
  scroll-snap-type: x mandatory;
  margin-block: -1rem;
  padding-block: 1rem;
  width: calc(100% + 2 * var(--content-spacing));
  margin-inline-start: calc(-1 * var(--content-spacing));

  ${BREAKPOINT_LG} {
    width: 100%;
    margin-inline-start: 0;
  }
`;

const Body = styled.div<{ open: boolean }>`
  display: flex;
  gap: 1rem;
  padding-inline: var(--content-spacing);

  ${BREAKPOINT_LG} {
    padding-inline: 0;
  }

  ${({ open }) =>
    open &&
    css`
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    `};
`;

const Item = styled.div<{ open: boolean }>`
  display: flex;
  width: 300px;
  scroll-snap-align: center;
  transition: width 0.25s ease-in-out;
  margin: -1rem;
  padding: 1rem;

  ${BREAKPOINT_LG} {
    scroll-snap-align: start;
    margin: 0;
    padding: 0;
  }

  ${({ open }) =>
    open &&
    css`
      width: auto;
    `};
`;

interface ICardGalleryProps extends PropsWithChildren {
  headline: string;
  open: boolean;
  toggleOpen: () => void;
}

export default function CardGallery(props: ICardGalleryProps) {
  const { headline, open, toggleOpen, children } = props;

  return (
    <Wrapper>
      <Header>
        <Headline>{headline}</Headline>

        <AccordionButton theme={'secondary'} onClick={toggleOpen} items={Children.count(children)}>
          {open ? Translations.cards.gallery.showLess : Translations.cards.gallery.showAll}
        </AccordionButton>
      </Header>

      <OverflowWrapper>
        <Body open={open}>
          {Children.map(children, (child, index) => (
            <Item key={index} open={open}>
              {child}
            </Item>
          ))}
        </Body>
      </OverflowWrapper>
    </Wrapper>
  );
}
