import { AtomEffect } from 'recoil';

export const LocalStorageEffect: (key: string) => AtomEffect<any> = (key) => ({ setSelf, onSet }) => {
  if (typeof window !== 'undefined') {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  }
};