import { AtomEffect } from 'recoil';

export const TimeStampEffect: AtomEffect<any> = ({ setSelf, onSet }) => {
  onSet((newValue, _, isReset) => {
    if (!isReset) {
      setSelf({
        ...newValue,
        timeStamp: new Date().getTime(),
      });
    }
  });
};
