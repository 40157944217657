// General
import { SVGProps } from 'react';

export default function Arrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='14' fill='none' {...props}>
      <path
        fill='currentColor'
        d='m1.025 7.636-.74-.634.74-.635L7.704.634 8.444 0l1.473 1.265-.739.635-4.896 4.206H19.714v1.792H4.281L9.183 12.1l.74.635L8.448 14l-.74-.634-6.683-5.73Z'
      />
    </svg>
  );
}
