// General
import { ButtonHTMLAttributes, FC } from 'react';
import { Button as StyledButton } from '../../common/Button';

const Button: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const { children, className, ...buttonProps } = props;

  return (
    <StyledButton className={className} {...buttonProps}>
      {children}
    </StyledButton>
  );
};

export default Button;