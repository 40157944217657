import { HTMLProps, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

interface IFieldProps {
  name: string;
  optional?: boolean;
  hasError?: boolean;
  forwardRef?: any;
}

export default function Field(props: IFieldProps & HTMLProps<HTMLInputElement>) {
  const { name, optional = false, hasError, forwardRef, ...inputProps } = props;
  const fieldProps = useFieldProps(name, optional);

  return <input {...inputProps} {...fieldProps} ref={forwardRef} />;
}

export const useFieldProps = (name: string, optional: boolean = false, defaultValue?: string) => {
  const formContext = useFormContext();

  return formContext.register(name, {
    validate: (value) => (optional || !!value ? true : 'Required'),
    value: defaultValue,
  });
};

export const useFieldValue = (name: string) => {
  const formContext = useFormContext();
  return useMemo(() => formContext.getValues(name), [name, formContext]);
};

export const useFieldError = (name: string) => {
  const formContext = useFormContext();
  const { error } = useMemo(() => formContext.getFieldState(name, formContext.formState), [name, formContext]);

  return error;
};
