// General
import styled from 'styled-components';
import { PropsWithChildren } from 'react';

// Icons
import CloseIcon from '../icons/CloseIcon';

// Styles
import { BREAKPOINT_SM } from '../../styles/Breakpoints';

const Wrapper = styled.div<{ open: boolean }>`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--color-primary);
  display: grid;
  padding-block-start: 1rem;
  padding-inline: 1rem;
  transform: translateY(${({ open }) => open ? '0' : 'calc(-100% - 1rem)'});
  transition: transform .5s ease-in-out;

  ${BREAKPOINT_SM} {
    grid-template-columns: 1rem 1fr 1rem;
    column-gap: 1rem;
    padding-inline: 3rem;
    padding-block-start: 1.5rem;
  }
`;

const BorderBottom = styled.span`
  background: var(--color-text-default);
  border: 1px solid #F63F21;
  height: 3px;
  margin-inline: -1rem;
  box-shadow: 0 5px 8px 4px rgba(236, 22, 22, 0.16), 0 0 6px #FF820E;
  margin-block-start: 1.5rem;

  ${BREAKPOINT_SM} {
    grid-area: 2 / 1 / 2 / 4;
    margin-inline: -3rem;
  }
`;

const CloseButton = styled.button`
  margin-block-end: .25rem;
  place-self: flex-end;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.8)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7));

  ${BREAKPOINT_SM} {
    grid-area: 1 / 3;
  }
`;

const ChildWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;

  ${BREAKPOINT_SM} {
    grid-area: 1 / 2;
  }
`;

interface IStickyActionBarProps extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
}

export default function StickyActionBar(props: IStickyActionBarProps) {
  const { open, onClose, children } = props;

  return (
    <Wrapper open={open}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>

      <ChildWrapper>
        {children}
      </ChildWrapper>

      <BorderBottom/>
    </Wrapper>
  );
}