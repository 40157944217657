import styled from 'styled-components';

const Wrapper = styled.div``;

export default function Profile (){
  return(
    <Wrapper>

    </Wrapper>
  )
}