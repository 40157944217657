// General
import styled, { css } from 'styled-components';
import { FC, useEffect, useState } from 'react';

// States
import { useCookieState } from '../../states/CookieState';

// Components
import { CheckboxField } from '../common/CheckboxField';
import Wysiwyg from '../common/Wysiwyg';
import { InternalStyledButton } from '../content/Container';

// Styles
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM } from '../../styles/Breakpoints';
import { Body2 } from '../../styles/FontStyles';
import { Translations } from '../../utils/Translations';

const Wrapper = styled.div`
  position: fixed;
  z-index: 20;
  margin: auto;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  width: auto;
  max-width: 850px;
  max-height: calc(100% - 2rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;

  background: rgba(55, 55, 55, 0.45);
  backdrop-filter: blur(22.5px);
  border: 1px solid var(--color-text-default);
  border-radius: 10px;

  ${BREAKPOINT_MD} {
    padding: 2rem;
    max-height: calc(100% - 8rem);
    left: 3rem;
    right: 3rem;
    bottom: 3rem;
  }

  ${BREAKPOINT_LG} {
    width: 36rem;
    left: initial;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: var(--content-max-width);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Text = styled(Wysiwyg)`
  margin-bottom: 1rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 1.5rem;
  }
`;

const AccordionBody = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-rows: ${({ open }) => (open ? 1 : 0)}fr;
  transition: grid-template-rows 0.3s ease-in-out;
`;

const AccordionContent = styled.div`
  overflow: hidden;
`;

const ReadMoreButton = styled.button.attrs({ type: 'button' })<{ active: boolean }>`
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;

  gap: 1rem;
  margin-bottom: 1.5rem;

  svg {
    width: 0.75rem;
    height: auto;
    transition: transform 300ms ease-out;
    transform: rotateX(0deg);

    ${({ active = false }) => active && css`
      transform: rotateX(180deg);
    `};
  }
`;

const ConsentItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 0 0 2rem;
  gap: 1rem;

  ${BREAKPOINT_MD} {
    padding: 2rem 0 3.5rem;
    gap: 2rem;
  }
`;

const ConsentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    gap: 1rem;
  }

  input {
    border-color: var(--color-text-default);
  }

  svg {
    color: var(--color-text-default);
  }
`;

const ConsentItemDescription = styled(Wysiwyg)`
  ${Body2};
  padding: 0 0 0 2.5rem;
  margin-bottom: 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 300ms ease-out;

  ${BREAKPOINT_SM} {
    flex-direction: row;
    gap: 1.5rem;
  }

  ${BREAKPOINT_MD} {
    justify-content: flex-end;
  }
`;

const CookieButton = styled(InternalStyledButton)`
  width: 100%;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-block: .5rem;
  height: auto;

  ${BREAKPOINT_MD} {
    width: max-content;
  }
`;


export const CookieConsent: FC = (props) => {
  const [cookieState, setCookieState] = useCookieState();

  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [checkedConsentItems, setCheckedConsentItems] = useState<string[]>(Translations.cookieConsent.consentItems?.map(({ key }) => key));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // next SSR fix
      setShowCookieConsent(cookieState.showConsent);
    }
  }, [cookieState.showConsent]);

  const onAcceptClick = () => {
    // accept all
    setCookieState({
      showConsent: false,
      allowedCookies: Translations.cookieConsent.consentItems.map(({ key }) => key),
    });
    document.body.classList.remove('no-scroll');
  };

  const onDeclineClick = () => {
    if (readMore) {
      // accept selection
      setCookieState({
        showConsent: false,
        allowedCookies: checkedConsentItems,
      });
    } else {
      // decline
      setCookieState({
        showConsent: false,
        allowedCookies: [],
      });
    }
    document.body.classList.remove('no-scroll');
  };

  const toggleReadMore = () => {
    document.body.classList.toggle('no-scroll', !readMore);
    setReadMore(!readMore)
  }

  if (showCookieConsent) {
    return (
      <Wrapper>
        <Content>
          <Text content={Translations.cookieConsent.text} />

          <ReadMoreButton active={readMore} onClick={toggleReadMore}>
            {readMore ? Translations.cookieConsent.buttonLabelReadLess : Translations.cookieConsent.buttonLabelReadMore}
            <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M1 1L6 6L11 1' stroke='white' strokeWidth='2' strokeLinecap='round' />
            </svg>
          </ReadMoreButton>

          <AccordionBody open={readMore}>
            <AccordionContent>
              <ConsentItemsWrapper>
                {Translations.cookieConsent.consentItems.map(({ key, title, description }) => (
                  <ConsentItem key={key}>
                    <CheckboxField
                      id={key}
                      label={title}
                      checked={checkedConsentItems.includes(key)}
                      onChange={({ target }) => {
                        const { checked } = target;
                        setCheckedConsentItems((items) => [...items.filter((item) => item !== key), ...(checked ? [key] : [])]);
                      }}
                      disabled={key === 'necessary'}
                    />
                    <ConsentItemDescription content={description} />
                  </ConsentItem>
                ))}
              </ConsentItemsWrapper>
            </AccordionContent>
          </AccordionBody>

          <ButtonsWrapper>
            <CookieButton theme={'secondary'} onClick={onDeclineClick}>
              {readMore ? Translations.cookieConsent.buttonLabelAcceptSelection : Translations.cookieConsent.buttonLabelDisagree}
            </CookieButton>

            <CookieButton theme={'tertiary'} onClick={onAcceptClick}>
              {readMore ? Translations.cookieConsent.buttonLabelAcceptAll : Translations.cookieConsent.buttonLabelAgree}
            </CookieButton>
          </ButtonsWrapper>
        </Content>
      </Wrapper>
    );
  }
  return null;
};
