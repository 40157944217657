import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useToast from '../../../hooks/useToast';
import { Notifications } from '../../layout/app/Layout';
import { StatusSection, StatusSectionProps } from './StatusSection';
import { MintView } from './MintView';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import LoadingAnimation from '../../common/LoadingAnimation';
import { Translations } from '../../../utils/Translations';
import { useBoosterContractApi } from '../../../services/contract/booster';
import { useIsApiReady } from '../../../services/ApiProvider';

export const StyledContainer = styled.div`
  display: grid;
  align-items: center;
  align-self: center;
  background: linear-gradient(180deg, #2c2b2b 0, rgba(32, 32, 32, 0.51) 100%);
  border: 1px solid #adadad;
  border-radius: 10px;
  gap: 2rem;
  grid-template-columns: auto;
  margin: 0 auto;
  padding: 1rem;
  max-width: 600px;

  ${BREAKPOINT_MD} {
    padding: 2rem;
  }

  ${BREAKPOINT_LG} {
    max-width: unset;
    padding: 0;
    gap: 5rem;
    margin: 0;
    grid-template-columns: 1fr minmax(50%, 1fr);
  }

  ${BREAKPOINT_XL} {
    grid-template-columns: 1fr 600px;
  }
`;

const Content = styled.div`
  display: grid;
  min-height: 12.5rem;

  ${BREAKPOINT_LG} {
    padding-block: 2.5rem;
    padding-inline-end: 2.5rem;
  }

  ${BREAKPOINT_XL} {
    padding-inline-end: 5rem;
  }
`;

const Booster = styled.video`
  border-radius: 10px;

  ${BREAKPOINT_LG} {
    margin-block: 0.625rem;
    margin-inline-start: 0.625rem;
    inline-size: min(600px, 100%);
  }
`;

const StyledLoadingAnimation = styled(LoadingAnimation)`
  font-size: 2rem;
  place-self: center;

  ${BREAKPOINT_MD} {
    font-size: 3rem;
  }
`;

export interface IContractInfo {
  supply: number;
  batchSupply: number;
}

export type TStatus = 'whitelist' | 'success' | 'soldOut' | 'paused' | 'notWhitelisted' | 'public';

export interface MintProps {
  headline: string;
  mintLabel: string;
  termsAndConditions: string;
  successView: StatusSectionProps;
  pausedView: StatusSectionProps;
  soldOutView: StatusSectionProps;
  notWhitelistedView: StatusSectionProps;
}

export const Mint: FC = () => {
  const boosterApi = useBoosterContractApi();
  const isApiReady = useIsApiReady();

  // Component State
  const [contractInfo, setContractInfo] = useState<IContractInfo>();
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<TStatus>('paused');

  // Utils
  const { addErrorToast } = useToast();
  const notifications = useContext(Notifications);

  const getStatus = (status: number) => {
    switch (status) {
      case 3:
        return 'soldOut';
      case 2:
        return 'public';
      case 0:
        return 'whitelist';
      case 1:
      default:
        return 'paused';
    }
  };

  const init = useCallback(async () => {
    if (isApiReady) {
      try {
        setLoading(true);
        const [status, supply, batchSupply] = await Promise.all([
          boosterApi.getBoosterContractStatus(),
          boosterApi.getBoostersMinted(),
          boosterApi.getBatchSupply(),
        ]);
        setStatus(getStatus(batchSupply <= supply ? 3 : status));
        setContractInfo({ supply, batchSupply });
      } catch {
        addErrorToast(notifications!.internalServerError);
      } finally {
        setLoading(false);
      }
    }
  }, [isApiReady]);

  useEffect(() => {
    if (isApiReady) {
      init();
    }
  }, [isApiReady]);

  const statusView = useMemo(() => {
    switch (status) {
      case 'whitelist':
      case 'public':
        return <MintView contractInfo={contractInfo} setStatus={setStatus} status={status} />;
      case 'success':
        return <StatusSection {...Translations.mint.successView} />;
      case 'soldOut':
        return <StatusSection {...Translations.mint.soldOutView} />;
      case 'paused':
        return <StatusSection {...Translations.mint.pausedView} />;
      case 'notWhitelisted':
        return <StatusSection {...Translations.mint.notWhitelistedView} />;
    }
  }, [status, contractInfo]);

  return (
    <StyledContainer>
      <Booster aria-hidden={true} muted loop playsInline autoPlay>
        <source type='video/webm' src='/images/Booster.webm' />
        <source type='video/mp4' src='/images/Booster.mp4' />
      </Booster>
      <Content>{loading ? <StyledLoadingAnimation /> : statusView}</Content>
    </StyledContainer>
  );
};
