import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { FC } from 'react';
import useFeatureToggle from './hooks/useFeatureToggle';
import Layout from './components/layout/app/Layout';
import Training from './components/content/training';
import Inventory from './components/content/inventory';
import BallerDetail from './components/content/ballers/BallerDetail';
import { Shop } from './components/content/shop';
import { History } from './components/content/history';
import { GlobalStyles } from './styles/GlobalStyles';
import { RecoilRoot } from 'recoil';
import { Mint } from './components/content/mint';
import { Claim } from './components/content/claim';
import Page404 from './components/content/404';
import AccessBlocked from './components/content/AccessBlocked';
import Dashboard from './components/content/dashboard';
import { ApiProvider } from './services/ApiProvider';
import ShopItemDetail from './components/content/shop/ShopItemDetail';
import RaffleDetail from './components/content/shop/RaffleDetail';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoginReturnPage } from './components/auth/LoginReturnPage';
import Profile from './components/content/profile';
import UserRoute from './components/layout/guards/UserRoute';

const App: FC = () => {
  const { TRAINING_ACTIVE, SHOP_ACTIVE, CLAIM_ACTIVE, MINT_ACTIVE } = useFeatureToggle();

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ApiProvider>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
              <Layout>
                <Outlet />
              </Layout>
            </GoogleOAuthProvider>
        </ApiProvider>
      ),
      children: [
        {
          path: '/',
          element: <Dashboard />,
        },
        {
          path: '/inventory',
          element: (
            <UserRoute>
              <Inventory />
            </UserRoute>
          ),
        },
        {
          path: '/profile',
          element: (
            <UserRoute>
              <Profile />
            </UserRoute>
          ),
        },
        {
          path: '*',
          element: <Page404 />,
        },
        {
          path: 'access-blocked',
          element: <AccessBlocked />,
        },
        TRAINING_ACTIVE
          ? {
            path: 'training',
            element: (
              <UserRoute>
                <Training />
              </UserRoute>
            ),
          }
          : {},
        SHOP_ACTIVE
          ? {
            path: 'shop',
            element: <Shop />,
          }
          : {},
        SHOP_ACTIVE
          ? {
            path: 'shop/history',
            element: (
              <UserRoute>
                <History />
              </UserRoute>
            ),
          }
          : {},
        SHOP_ACTIVE
          ? {
              path: 'shop/items/:id',
              element: <ShopItemDetail />,
            }
          : {},
        SHOP_ACTIVE
          ? {
              path: 'shop/raffles/:id',
              element: <RaffleDetail />,
            }
          : {},
        {
          path: 'ballers/:ballerId',
          element: (
            <UserRoute>
              <BallerDetail />
            </UserRoute>
          ),
        },
        MINT_ACTIVE
          ? {
            path: 'mint',
            element: (
              <UserRoute>
                <Mint />
              </UserRoute>
            ),
          }
          : {},
        CLAIM_ACTIVE
          ? {
            path: 'claim',
            element: (
              <UserRoute>
                <Claim />
              </UserRoute>
            ),
          }
          : {},
        {
          path: '/login/redirect/discord',
          element: (<LoginReturnPage type={'DISCORD'} urlAuthCodeParam={'code'} />),
        },
      ],
    },
  ]);

  return (
    <RecoilRoot>
      <GlobalStyles />
      <RouterProvider router={router} />
    </RecoilRoot>
  );
};

export default App;
