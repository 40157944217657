import { FC } from 'react';
import styled, { css } from 'styled-components';
import Wysiwyg from '../common/Wysiwyg';
import { Glass, InternalItemWrapper, InternalStyledButton, ShimmerLoadingAnimation } from './Container';
import { BREAKPOINT_LG, BREAKPOINT_XL } from '../../styles/Breakpoints';

const Wrapper = styled(InternalItemWrapper)`
  position: relative;
`;

const PlaceholderCard = styled.div<{ isLoading: boolean }>`
  ${Glass};
  opacity: 0.25;
  aspect-ratio: 3 / 4.75;

  &:nth-child(n + 5) {
    display: none;
  }

  ${BREAKPOINT_LG} {
    &:nth-child(n + 7) {
      display: inline;
    }
  }

  ${BREAKPOINT_XL} {
    &:nth-child(n) {
      display: initial;
    }
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      ${ShimmerLoadingAnimation};
    `};
`;

const TextWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  gap: 1rem;
  place-items: center;
  place-content: center;
  text-align: center;
  padding: 2rem;
`;

const Headline = styled.h3`
  font-size: 1.875rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const Text = styled(Wysiwyg)`
  margin-bottom: 0;
  gap: 0.5rem;

  a {
    text-decoration: underline;
  }
`;

export interface NFTPlaceholderProps {
  headline: string;
  text: string;
  button?: {
    title: string;
    href?: string;
    target: string;
  };
}

export const NFTPlaceholder: FC<NFTPlaceholderProps & { loading: boolean }> = (props) => {
  const { headline, text, loading, button } = props;
  return (
    <Wrapper>
      {Array(8)
        .fill('')
        .map((_, i) => (
          <PlaceholderCard key={i} isLoading={loading} />
        ))}

      {!loading && (
        <TextWrapper>
          <Headline>{headline}</Headline>
          <Text content={text} />
          {button && (
            <InternalStyledButton theme={'tertiary'} href={button.href}>
              {button.title}
            </InternalStyledButton>
          )}
        </TextWrapper>
      )}
    </Wrapper>
  );
};
