import { useApi } from './ApiProvider';
import { IAccount } from '@m3ta-collective/mooncourt-utilities-library';
import { IUserAddress } from '../types/address';
import { useSetRecoilState } from 'recoil';
import { AccountState, AddressState, WalletState } from '../states/AppData';

interface IGlobalAddressProps {
  id?: string;
  firstname: string;
  lastname: string;
  company?: string;
  city: string;
  street: string;
  postalCode: string;
  houseNumber: string;
  country: string;
  email: string;
  phone?: string;
  wallet?: string;
}

interface IWalletRequestProps {
  wallet: string;
  walletAuthorization: string;
}

interface IUpdateAddressProps {
  wallet: string;
  id?: string;
  address: IGlobalAddressProps;
}

const BASE_URL = '/v2/account/user';

export const useUserApi = () => {
  const apiInstance = useApi();
  const setAccountState = useSetRecoilState(AccountState);
  const setWalletState = useSetRecoilState(WalletState);
  const setAddressState = useSetRecoilState(AddressState);

  const getUser = async () => {
    return (await apiInstance.get<IAccount>(`${BASE_URL}`)).data;
  };

  const refreshAccountState = async () => {
    try {
      const accountResponse = (await apiInstance.get(BASE_URL)).data;
      setAccountState(accountResponse);
    } catch (error) {
      console.log('error refreshing user account:', error);
    }
  };

  const getStats = async () => {
    return (await apiInstance.get(`${BASE_URL}/stats`)).data;
  };

  const getWallets = async () => {
    return (await apiInstance.get(`${BASE_URL}/wallets`)).data;
  };

  const refreshWalletState = async () => {
    try{
      const walletsResponse =  await apiInstance.get(`${BASE_URL}/wallets`);
      setWalletState(walletsResponse.data)
    } catch (error) {
      console.log('error refreshing user wallets:',error)
    }
  };

  const connectWallet = async ({ wallet, walletAuthorization }: IWalletRequestProps) => {
    return (
      await apiInstance.post(`${BASE_URL}/wallets/connect`, wallet, {
        headers: {
          'wallet-authorization': walletAuthorization,
        },
      })
    ).data;
  };

  const disconnectWallet = async ({ wallet, walletAuthorization }: IWalletRequestProps) => {
    return (
      await apiInstance.post(`${BASE_URL}/wallets/disconnect`, wallet, {
        headers: {
          'wallet-authorization': walletAuthorization,
        },
      })
    ).data;
  };

  const getAddress = async () => {
    return (await apiInstance.get<IUserAddress>(`${BASE_URL}/address`)).data;
  };

  const updateAddress = async (data: IUpdateAddressProps) => {
    return (await apiInstance.patch<IUserAddress>(`${BASE_URL}/address`, data)).data;
  };

  const refreshAddressState = async ()=>{
    try{
      const addressResponse =  await apiInstance.get(`${BASE_URL}/address`);
      addressResponse?.data && setAddressState(addressResponse.data)
    }catch(error){
      console.log('error refreshing user address:', error)
    }
  }

  // TODO @daniel fix nonce generation / verification

  // const getNonce = async () => {
  //   return (await apiInstance.get<{ message: string; nonce: string }>(`${BASE_URL}/wallets/connect/nonce`)).data;
  // };
  //
  // const verifyNonce = async (walletAddress: string, signedMessage: string, nonce: string) => {
  //   return (
  //     await apiInstance.post<{ authToken: string; walletAddress: string }>(
  //       `${BASE_URL}/wallets/connect/nonce`,
  //       JSON.stringify({
  //         walletAddress,
  //         signedMessage,
  //       }),
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Nonce: nonce,
  //         },
  //       },
  //     )
  //   ).data.authToken;
  // };

  return {
    getUser,
    refreshAccountState,
    getStats,
    getWallets,
    refreshWalletState,
    connectWallet,
    disconnectWallet,
    getAddress,
    updateAddress,
    refreshAddressState,
  };
};
