import { useApi } from './ApiProvider';
import { IClaimRewardsResponse, IRewardStatus } from '../types/rewards';

const BASE_PATH = '/v2/staking/rewards/daily';

export const useRewardApi = () => {
  const apiInstance = useApi();

  const getRewardStatus = async () => {
    return (await apiInstance.get<IRewardStatus>(`${BASE_PATH}/status`)).data;
  };

  const claimDailyRewards = async () => {
    return (await apiInstance.post<IClaimRewardsResponse>(`${BASE_PATH}/claim`)).data;
  };

  return {
    getRewardStatus,
    claimDailyRewards,
  };
};
