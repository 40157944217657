import { atom } from 'recoil';

export const GlobalAddressModalState = atom<boolean>({
  key: 'address-modal-state',
  default: false,
});

export const GlobalAddressModalLoadingState = atom<boolean>({
  key: 'address-modal-loading-state',
  default: false,
});
