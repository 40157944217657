import styled from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { HTMLAttributes } from 'react';
import LoadingSpinner from '../../common/LoadingSpinner';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background-color: var(--color-text-highlight);
  border: 1px solid var(--color-text-default);
  padding-block: 1.25rem;
  padding-inline: 0.75rem;

  ::before {
    position: absolute;
    content: '';
    top: 2rem;
    height: calc(100% - 2 * 2rem);
    width: calc(100% + 2px);
    background-color: var(--color-text-highlight);
    margin: -1px;
    padding: 1px;

    ${BREAKPOINT_LG} {
      top: 2.5rem;
      height: calc(100% - 2 * 2.5rem);
      width: calc(100% + 2px);
    }
  }

  ::after {
    position: absolute;
    content: '';
    left: 2.5rem;
    width: calc(100% - 2 * 2.5rem);
    height: calc(100% + 2px);
    background-color: var(--color-text-highlight);
    margin: -1px;
    padding: 1px;

    ${BREAKPOINT_LG} {
      left: 3rem;
      width: calc(100% - 2 * 3rem);
      height: calc(100% + 2px);
    }
  }

  ${BREAKPOINT_MD} {
    width: 15rem;
  }

  ${BREAKPOINT_XXL} {
    width: 100%;
  }
`;

const Value = styled.h6`
  z-index: 1;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1;
  margin-block-end: 0.75rem;
  font-weight: 700;

  ${BREAKPOINT_LG} {
    font-size: 3.125rem;
  }
`;

const Name = styled.h6`
  z-index: 1;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.1;

  ${BREAKPOINT_LG} {
    font-size: 1.5rem;
  }
`;

const Label = styled.p`
  z-index: 1;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--color-grey-700);
  font-weight: 600;
  flex-wrap: wrap;
`;

export interface IStatTileProps extends HTMLAttributes<HTMLDivElement> {
  value: string | number;
  name: string;
  label?: string;
  loading?: boolean;
}

export default function StatTile(props: IStatTileProps) {
  const { value, name, label, loading = false, ...htmlAttributes } = props;

  return (
    <Wrapper {...htmlAttributes}>
      <Value>{loading ? <LoadingSpinner /> : value}</Value>

      <Name>{name}</Name>

      {label && <Label>{label}</Label>}
    </Wrapper>
  );
}
