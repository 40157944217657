import { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAccount } from 'wagmi';
import { useRecoilValue } from 'recoil';
import useToast from '../../../hooks/useToast';
import { Notifications } from './Layout';
import PointsIcon from '../../icons/PointsIcon';
import Level from '../../icons/Level';
import Mooncourt from '../../icons/Mooncourt';
import useFeatureToggle from '../../../hooks/useFeatureToggle';
import { BREAKPOINT_MD, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import Navigation from '../header/Navigation';
import LinkHandler from '../../common/LinkHandler';
import LoadingSpinner from '../../common/LoadingSpinner';
import Chip from '../../common/Chip';
import { useIsApiReady } from '../../../services/ApiProvider';
import Tooltip from '../../common/Tooltip';
import { Translations } from '../../../utils/Translations';
import { useRewardApi } from '../../../services/reward';
import ProfileMenu from './ProfileMenu';
import { useUserApi } from '../../../services/user';
import { AccountState, AuthTokenState } from '../../../states/AppData';

const Wrapper = styled.header<{ hasBackground: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block-end: 2.5rem;

  ${BREAKPOINT_MD} {
    padding-block: 1.5rem;
  }

  ${BREAKPOINT_XL} {
    position: sticky;
    top: 0;
    z-index: 10;

    ::before {
      content: '';
      position: absolute;
      width: calc(100% + (var(--content-spacing) * 2)); // TODO: CHECK IF THIS COURSES OTHER BUGS
      height: 100%;
      backdrop-filter: blur(10px);
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
      display: flex;
      top: 0;
      margin-inline: calc(-1 * var(--content-spacing));
      opacity: ${({ hasBackground }) => (hasBackground ? 1 : 0)};
      transition: opacity 0.3s ease-in-out;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled(LinkHandler)`
  display: grid;
  font-size: 2.125rem;
  color: var(--color-primary);
  line-height: 1;
  z-index: 11;

  p {
    z-index: 10;
  }

  ${BREAKPOINT_MD} {
    font-size: 2.75rem;
    width: 5.8125rem;
    place-content: center;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  z-index: 10;
`;

const DesktopButtonWrapper = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    display: contents;
  }
`;

const Loader = styled(LoadingSpinner)`
  --width: 2px;
  font-size: 0.75rem;
`;

const StyledTooltip = styled(Tooltip)`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
  }
`;

interface IHeader {
  className?: string;
}

export default function Header(props: IHeader) {
  const { className } = props;
  const { address } = useAccount();
  const [loading, setLoading] = useState<boolean>(true);
  const { addToast, addErrorToast } = useToast();
  const notifications = useContext(Notifications);
  const authTokenState = useRecoilValue(AuthTokenState);
  const accountState = useRecoilValue(AccountState);
  const [hasBackground, setHasBackground] = useState(false);

  const userApi = useUserApi();

  const { claimDailyRewards } = useRewardApi();
  const isApiReady = useIsApiReady();

  const featureToggles = useFeatureToggle();

  const fetchData = useCallback(async () => {
    if (isApiReady) {
      setLoading(true);

      claimDailyRewards()
        .then(async (rewardsResponse) => {
          const [account,wallet, address] = await Promise.all([
            await userApi.refreshAccountState(),
            await userApi.refreshWalletState(),
            await userApi.refreshAddressState(),
          ]);

          if (rewardsResponse?.pointsAdded && notifications) {
            addToast({
              headline: notifications.dailyRewardPointsAdded.headline,
              text: notifications.dailyRewardPointsAdded.text.replace('{POINTS}', rewardsResponse.pointsAdded.toString()),
              type: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('error claiming daily rewards:', error);
          addErrorToast(notifications!.internalServerError);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiReady]);

  const scrollHandler = useCallback(() => {
    setHasBackground(!!window.scrollY);
  }, []);

  useEffect(() => {
    fetchData();

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, fetchData, isApiReady]);

  return (
    <Wrapper className={className} hasBackground={hasBackground}>
      <Container>
        <LogoLink to='/'>
          <Mooncourt />
        </LogoLink>

        <ActionWrapper>
          {authTokenState.token && accountState && (
            <DesktopButtonWrapper>
              {featureToggles.TRAINING_ACTIVE && (
                <Chip>
                  <PointsIcon />
                  {loading ? <Loader /> : accountState?.account?.points}
                </Chip>
              )}
              {featureToggles.SHOP_ACTIVE && (
                <>
                  <Chip>
                    <Level />
                    <p>Level {loading ? <Loader /> : `${accountState?.account?.level || 0}`}</p>

                    <StyledTooltip translation={Translations.tooltip.level} />
                  </Chip>
                </>
              )}
            </DesktopButtonWrapper>
          )}

          <ProfileMenu />

          <Navigation />
        </ActionWrapper>
      </Container>
    </Wrapper>
  );
}
