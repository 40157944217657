import styled from 'styled-components';
import AppleSignin from 'react-apple-signin-auth';
import React, { useCallback, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from '../../common/Button';
import { useOAuthWindow } from '../../../utils/oAuthWindow';
import { useSSOApi } from '../../../services/sso';
import GoogleIcon from '../../icons/GoogleIcon';
import DiscordIcon from '../../icons/DiscordIcon';
import AppleIcon from '../../icons/AppleIcon';
import { useSetRecoilState } from 'recoil';
import { AuthTokenState } from '../../../states/AppData';

const Wrapper = styled.div`
  display: grid;
  gap: 12px;
  overflow: hidden;
`;

const IconButton = styled(Button)`
  justify-content: center;
  width: 100%;
  flex-shrink: 0;

  svg {
    font-size: 1.5rem;
  }
`;

interface ISSOButtonProps {
  onClose: ()=>void;
}

export default function SSOButtons(props:ISSOButtonProps) {
  const {onClose} = props;
  const ssoApi = useSSOApi();
  const { childWindow, openWindow, buildDiscordUrl } = useOAuthWindow();
  const setAuthTokenState = useSetRecoilState(AuthTokenState)

  const verifyCallback = useCallback(async (verifyToken: string, type: 'GOOGLE' | 'APPLE' | 'DISCORD') => {
    try {
      let validatedToken = null;

      if (type === 'GOOGLE') {
        validatedToken = await ssoApi.googleLogin(verifyToken);
      } else if (type === 'APPLE') {
        validatedToken = await ssoApi.appleLogin(verifyToken);
      } else if (type === 'DISCORD') {
        validatedToken = await ssoApi.discordLogin(verifyToken);
      }

      if(validatedToken && validatedToken?.token){
        setAuthTokenState({token: validatedToken.token});
        onClose();
      }
    } catch (error) {
      console.log('verifyCallback => error =>', error);
    }
  }, []);

  const ssoAuthCodePostMessageListener = async (event: MessageEvent<any>) => {
    console.log('NEW DISCORD LOGIN =>', JSON.stringify(event));
    if (event?.origin === window.location.origin && event?.type === 'message' && event?.data?.type === 'sso-auth-code') {
      console.log('Event =>', event?.data?.code, event?.data?.ssoType);
      await verifyCallback(event.data.code, event.data.ssoType);
    }
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      if (tokenResponse.access_token) {
        await verifyCallback(tokenResponse.access_token, 'GOOGLE');
      }
    },
  });

  const discordLoginHandler = () => {
    const url = buildDiscordUrl();
    openWindow(url);
  };

  useEffect(() => {
   // if (childWindow?.current) {
      window.addEventListener('message', ssoAuthCodePostMessageListener);
   //  }

    return () => {
      window.removeEventListener('message', ssoAuthCodePostMessageListener);
    };
  }, []);

  return (
    <Wrapper>
      <AppleSignin
        onError={(error: any) => {
          console.log(error);
        }}
        onSuccess={async (response: any) => {
          if (response?.authorization?.code) {
            await verifyCallback(response.authorization.code, 'APPLE');
          }
        }}
        uiType={'dark'}
        render={(props: any) => {
          return (
            <IconButton {...props} type={'button'}>
              <AppleIcon /> Sign in with Apple
            </IconButton>
          );
        }}
        authOptions={{
          clientId: `${process.env.REACT_APP_APPLE_OAUTH_CLIENT_ID}`,
          scope: 'email',
          redirectURI: `${process.env.REACT_APP_OAUTH_REDIRECT_BASE_URL}apple`,
          usePopup: true,
        }}
      />

      <IconButton onClick={() => googleLoginHandler()} type={'button'}>
        <GoogleIcon />
        Sign in with Google
      </IconButton>

      <IconButton onClick={discordLoginHandler} type={'button'}>
        <DiscordIcon style={{ fontSize: '1rem' }} />
        Sign in with Discord
      </IconButton>
    </Wrapper>
  );
}
