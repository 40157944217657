import styled from 'styled-components';
import { BREAKPOINT_MD, BREAKPOINT_XL } from '../../styles/Breakpoints';
import { Button } from '../common/Button';
import LinkHandler from '../common/LinkHandler';
import { FC } from 'react';

const Wrapper = styled.div`
  display: grid;
  height: 100%;
  place-content: center;

  ${BREAKPOINT_XL} {
    padding-inline-end: 8.5rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 3rem;
  max-width: 50rem;
`;

const Headline = styled.h1`
  font-size: 2.75rem;
  line-height: 1.1;
  text-transform: uppercase;
  margin-block-end: 1rem;

  ${BREAKPOINT_MD} {
    font-size: 3.75rem;
  }
`;

const SubHeadline = styled.p`
  font-size: 1rem;
  line-height: 1.3;
  margin-block-end: 1.5rem;
  text-align: center;

  ${BREAKPOINT_MD} {
    font-size: 1.125rem;
  }
`;

const StyledLinkHandler = styled(LinkHandler)`
  &:hover {
    text-decoration: none;
  }
`;

const AccessBlocked: FC = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Headline>access blocked</Headline>

        <SubHeadline>
          Due to suspicous activities associated with your wallet, your access has been restricted. Contact us by e-mail, if your account
          suspension requires a re-evaluation.
        </SubHeadline>

        <StyledLinkHandler to={'mailto:support@mooncourt.xyz?subject=access%20request'}>
          <Button>Contact</Button>
        </StyledLinkHandler>
      </ContentWrapper>
    </Wrapper>
  );
};

export default AccessBlocked;
