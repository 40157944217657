// General
import { SVGProps } from 'react';

export default function GoogleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M21 12.2105C21 17.3456 17.4258 21 12.1475 21C7.08689 21 3 16.979 3 12C3 7.02097 7.08689 3 12.1475 3C14.6115 3 16.6844 3.88911 18.2816 5.35524L15.7918 7.71048C12.5348 4.61855 6.47828 6.94113 6.47828 12C6.47828 15.1391 9.02705 17.6831 12.1475 17.6831C15.7697 17.6831 17.127 15.1282 17.341 13.8036H12.1475V10.7081H20.8561C20.941 11.169 21 11.6117 21 12.2105Z'
        fill='currentColor'
      />
    </svg>
  );
}
