import { FC, SVGProps } from 'react';

export default function TrainingIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 21 21' {...props}>
      <path
        fill='currentColor'
        d='M12.4687 18.8016v-.0985c0-1.3658.4389-2.6291 1.1854-3.6586l2.141 2.141c-.9638.7629-2.0918 1.3207-3.3264 1.6161Zm-1.9605.2296H10.5c-2.00156 0-3.84316-.689-5.29512-1.8416L10.5 11.8904l1.7473 1.7473C11.1521 15.0363 10.5 16.7918 10.5 18.7031c0 .1108.0041.2174.0082.3281Zm3.1336-6.7839L11.8904 10.5l5.2992-5.29512c1.1526 1.45196 1.8416 3.29356 1.8416 5.29512v.0082c-.1107-.0041-.2173-.0082-.3281-.0082-1.9113 0-3.6668.6521-5.0613 1.7473Zm5.1598.2214c-.2912 1.2346-.8532 2.3667-1.6161 3.3264l-2.141-2.141c1.0254-.7465 2.2928-1.1854 3.6586-1.1854h.0985ZM10.5 9.10957 8.75273 7.3623C9.84785 5.96367 10.5 4.2082 10.5 2.29687c0-.11074-.0041-.21738-.0082-.32812H10.5c2.0016 0 3.8432.68906 5.2951 1.8416L10.5 9.10957Zm-3.1541-3.1541L5.20488 3.81035c.96387-.76289 2.0918-1.3207 3.32637-1.61601v.09843c0 1.36582-.43887 2.6291-1.18535 3.6586v.0041ZM5.95547 7.3459c-1.02949.74648-2.29277 1.18535-3.6586 1.18535h-.09843c.29121-1.23457.85312-2.3666 1.61601-3.32637L5.95547 7.3459Zm-3.98672 3.1459c.11074.0041.21738.0082.32812.0082 1.91133 0 3.6668-.65215 5.06133-1.74727L9.10957 10.5l-5.29922 5.2951c-1.15254-1.4519-1.8416-3.2935-1.8416-5.2951v-.0082ZM10.5 21c2.7848 0 5.4555-1.1062 7.4246-3.0754C19.8938 15.9555 21 13.2848 21 10.5c0-2.78477-1.1062-5.45549-3.0754-7.42462C15.9555 1.10625 13.2848 0 10.5 0 7.71523 0 5.04451 1.10625 3.07538 3.07538 1.10625 5.04451 0 7.71523 0 10.5c0 2.7848 1.10625 5.4555 3.07538 7.4246C5.04451 19.8938 7.71523 21 10.5 21Z'
      />
    </svg>
  );
}
