import styled from 'styled-components';
import TrainingsTab from './TrainingsTab';
import RewardsTab from './RewardsTab';
import { ITrainingStatus } from '../../../types/stake';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { useState } from 'react';
import { IRewardStatus } from '../../../types/rewards';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  ${BREAKPOINT_MD} {
    grid-column: 1 / 8;
    grid-row: 2;
  }

  ${BREAKPOINT_XXL} {
    grid-column: 1 / 15;
  }
`;

const Header = styled.button<{ currentIndex: number }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 3.125rem;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.375rem);
  padding: 0.25rem;

  ${BREAKPOINT_XL} {
    width: 20rem;
  }
`;

const Tab = styled.div<{ active: boolean }>`
  z-index: 1;
  padding: 0.625rem;
  color: ${({ active }) => (active ? 'var(--color-primary)' : 'var(--color-grey-700)')};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.25s ease-in-out;

  &:first-child {
    grid-row: 1;
    grid-column: 1;
  }

  &:last-child {
    grid-row: 1;
    grid-column: 2;
  }
`;

const Indicator = styled.span<{ currentIndex: number }>`
  grid-row: 1;
  grid-column: 1 / -1;
  display: grid;
  transition: grid-template-columns 0.25s ease-in-out;
  grid-template-columns: ${({ currentIndex }) => `${currentIndex}fr`} 1fr ${({ currentIndex }) => (currentIndex === 1 ? '0fr' : '1fr')};
  height: 2.75rem;

  ::after {
    content: '';
    grid-column: 2;
    border-radius: 3.125rem;
    border: 1px solid var(--color-primary);
    background: var(--color-text-highlight);
    box-shadow: 0 0 10px 0 var(--color-primary) inset;
    transition: grid-column 1ms ease-in-out;
  }
`;

export const TabSwitchBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.5rem;
  padding: 1.25rem 0.75rem;

  ${BREAKPOINT_LG} {
    padding: 2rem;
  }

  ${BREAKPOINT_XXL} {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 2rem;
  }
`;

export const TabSwitchHeadline = styled.h4`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
`;

export const TabSwitchInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${BREAKPOINT_MD} {
    gap: 1.75rem;
  }

  ${BREAKPOINT_XXL} {
    :first-child {
      grid-column: 1 / 4;
    }

    :last-child {
      grid-column: 4 / -1;
    }
  }
`;

const OverflowWrapper = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  box-shadow: 0 0 20px 0 #000, 0 0 20px 0 #000;
`;

interface ITrainingsTabSwitchProps {
  rewardStatus?: IRewardStatus;
  trainingStatus?: ITrainingStatus;
  loading: boolean;
}

export default function TrainingTabSwitch(props: ITrainingsTabSwitchProps) {
  const { rewardStatus, trainingStatus, loading } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  function toggleTabSwitch() {
    if (currentIndex === 0) {
      setCurrentIndex(1);
    } else {
      setCurrentIndex(0);
    }
  }

  return (
    <Wrapper>
      <Header currentIndex={currentIndex} onClick={toggleTabSwitch}>
        <Tab active={currentIndex === 0}>Momentum</Tab>

        <Indicator currentIndex={currentIndex} />

        <Tab active={currentIndex === 1}>Team</Tab>
      </Header>

      <OverflowWrapper>
        {currentIndex === 0 && <RewardsTab rewardStatus={rewardStatus} loading={loading} />}
        {currentIndex === 1 && <TrainingsTab trainingStatus={trainingStatus} />}
      </OverflowWrapper>
    </Wrapper>
  );
}
