import { SVGProps } from 'react';

export default function RocketIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.96796 8.94477C8.81696 5.95216 10.2592 4.61801 11.5802 4.01685C12.735 3.48952 13.9558 3.45261 14.9235 3.57917C15.05 4.54418 15.0131 5.76496 14.4858 6.92245C13.8846 8.24078 12.5505 9.68568 9.55786 10.5347C9.38912 10.1946 9.16764 9.88343 8.89342 9.61185C8.61921 9.34027 8.30809 9.11616 7.97059 8.94741L7.96796 8.94477ZM3.92068 9.83597C3.95232 9.84124 3.98396 9.84388 4.0156 9.84388H6.40705C7.00294 9.84388 7.57509 10.0812 7.99696 10.503C8.41883 10.9249 8.65613 11.4971 8.65613 12.0929V14.2735C8.65613 14.2761 8.65613 14.2761 8.65613 14.2787V15.75L12.8748 13.6407V10.4793C16.6584 8.13005 16.4712 4.36489 16.0045 2.4955C14.1351 2.02881 10.37 1.8416 8.02069 5.62522H6.32268C6.32004 5.62522 6.3174 5.62522 6.31477 5.62522H4.85933L2.75 9.83333L3.92068 9.83597ZM2.76318 15.7368C2.76318 15.7368 5.8349 15.9478 7.14004 14.6426C8.04706 13.7356 8.04706 12.267 7.14004 11.36C6.23303 10.4529 4.76441 10.4529 3.8574 11.36C2.55225 12.6651 2.76318 15.7368 2.76318 15.7368ZM4.45328 14.0467C4.45328 14.0467 4.38209 13.0263 4.81714 12.5913C5.11772 12.2907 5.60814 12.2907 5.90872 12.5913C6.2093 12.8919 6.2093 13.3823 5.90872 13.6829C5.47367 14.1179 4.45328 14.0467 4.45328 14.0467ZM13.5076 6.04708C13.5076 5.76737 13.3965 5.49911 13.1987 5.30132C13.0009 5.10353 12.7326 4.99242 12.4529 4.99242C12.1732 4.99242 11.9049 5.10353 11.7072 5.30132C11.5094 5.49911 11.3983 5.76737 11.3983 6.04708C11.3983 6.3268 11.5094 6.59505 11.7072 6.79284C11.9049 6.99063 12.1732 7.10175 12.4529 7.10175C12.7326 7.10175 13.0009 6.99063 13.1987 6.79284C13.3965 6.59505 13.5076 6.3268 13.5076 6.04708Z'
        fill='currentColor'
      />
    </svg>
  );
}
