import { SVGProps } from 'react';

export default function InfoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.92309 2.42218C11.9122 2.42218 13.8199 3.21235 15.2264 4.61887C16.6329 6.0254 17.4231 7.93305 17.4231 9.92218C17.4231 11.9113 16.6329 13.819 15.2264 15.2255C13.8199 16.632 11.9122 17.4222 9.92309 17.4222C7.93397 17.4222 6.02631 16.632 4.61979 15.2255C3.21327 13.819 2.42309 11.9113 2.42309 9.92218C2.42309 7.93305 3.21327 6.0254 4.61979 4.61887C6.02631 3.21235 7.93397 2.42218 9.92309 2.42218ZM9.92309 19.1529C12.3712 19.1529 14.7191 18.1804 16.4502 16.4493C18.1813 14.7182 19.1539 12.3703 19.1539 9.92218C19.1539 7.47402 18.1813 5.12614 16.4502 3.39504C14.7191 1.66393 12.3712 0.691406 9.92309 0.691406C7.47494 0.691406 5.12706 1.66393 3.39595 3.39504C1.66485 5.12614 0.692322 7.47402 0.692322 9.92218C0.692322 12.3703 1.66485 14.7182 3.39595 16.4493C5.12706 18.1804 7.47494 19.1529 9.92309 19.1529ZM8.48078 12.8068H7.6154V14.5376H8.48078H11.3654H12.2308V12.8068H11.3654H11.0769V9.63371V8.76833H10.2116H8.48078H7.6154V10.4991H8.48078H9.34617V12.8068H8.48078ZM11.0769 7.61448V5.30679H8.76925V7.61448H11.0769Z'
        fill='currentColor'
      />
    </svg>
  );
}
