// General imports
import { configureChains } from '@wagmi/core';
import { mainnet, sepolia } from '@wagmi/core/chains';

// Provider imports
import { publicProvider } from '@wagmi/core/providers/public';

// Connector imports
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';


// Configure chains for connectors to support
export const { chains, publicClient, webSocketPublicClient } = configureChains([mainnet, sepolia], [
  publicProvider(),
]);

// Set up supported wallet connectors
export const connectors = [
  new MetaMaskConnector({ chains }),
  new CoinbaseWalletConnector({
    chains,
    options: {
      appName: 'Mooncourt',
    },
  }),
  new WalletConnectConnector({
    chains,
    options: {
      projectId: '4a17b3ff695b22a7cf8b7b4b8326a8a8',
      showQrModal: true,
    },
  }),
];
