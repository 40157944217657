import { SVGProps } from 'react';

export default function HomeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.7185 1.62152L12.9977 1L12.2769 1.62152L0 12.1685L1.44625 13.8918L2.58093 12.9124V23.9537V25.0838H3.69701H22.2983H23.4144V23.9537V12.9124L24.5537 13.8918L26 12.1685L13.7231 1.62152H13.7185ZM4.81309 22.8237V10.9961L12.9977 3.96633L21.1823 10.9961V22.8237H17.462V14.9135V13.7835H16.3459H9.64944H8.53336V14.9135V22.8237H4.81309ZM10.7655 22.8237V16.0435H15.2298V22.8237H10.7655Z'
        fill='currentColor'
      />
    </svg>
  );
}
