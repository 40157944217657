import styled, { css } from 'styled-components';
import { ILink } from '../../../types/link';
import Mooncourt from '../../icons/Mooncourt';
import OpenSeaIcon from '../../icons/OpenSeaIcon';
import {
  CardAttribute,
  CardAttributes,
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  CardImageWrapper,
  CardOpenSeaLink,
  CardTitle,
  CardWrapper,
  InnerCardWrapper,
} from './CardStyles';
import { Translations } from '../../../utils/Translations';

const StyledCardWrapper = styled(CardWrapper)`
  cursor: pointer;
`;

const SelectIndicator = styled.input.attrs({ type: 'checkbox' })<{ selected: boolean }>`
  position: absolute;
  z-index: 2;
  display: grid;
  //top: 1.5rem;
  //right: 1.5rem;
  //width: 1.5rem;
  //height: 1.5rem;
  top: 8cqw;
  right: 8cqw;
  width: 8cqw;
  height: 8cqw;
  background: var(--select-background, linear-gradient(180deg, #ffe7e7 0%, rgba(255, 255, 255, 0) 100%));
  border-radius: 100%;
  border: 1.5px solid var(--color-text-default);
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7));
  pointer-events: auto;

  ::after {
    content: '✓';
    //font-size: 1.125rem;
    font-size: 6cqw;
    //line-height: 1.375rem;
    line-height: 8cqw;
    //padding-inline: 0.25rem;
    padding-inline: 1.33cqw;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  ${({ selected }) =>
    selected &&
    css`
      --select-background: linear-gradient(189.04deg, #f63f21 5.93%, rgba(246, 63, 33, 0) 94.39%);

      :after {
        opacity: 1;
      }
    `};
`;

export interface IBoosterCardProps {
  id: number;
  title: string;
  image: string;
  selected: boolean;
  toggleSelect?: () => void;
  openseaLink: ILink;
}

export default function BoosterCard(props: IBoosterCardProps) {
  const { id, title, image, selected, toggleSelect, openseaLink } = props;

  function handleClick(e: any) {
    e.stopPropagation();
    toggleSelect && toggleSelect();
  }

  return (
    <CardContainer>
      <StyledCardWrapper onClick={handleClick}>
        <SelectIndicator selected={selected} />

        <InnerCardWrapper>
          <CardImageWrapper itemAvailable={true}>
            <video src={image} muted autoPlay loop playsInline />
          </CardImageWrapper>

          <CardBody>
            <CardHeader>
              <CardTitle>{title}</CardTitle>
            </CardHeader>

            <CardAttributes>
              <CardAttribute>
                <p>{Translations.cards.attributes.id}</p>
                <p>#{id}</p>
              </CardAttribute>
            </CardAttributes>

            <CardFooter>
              <CardOpenSeaLink to={openseaLink.href} {...openseaLink} onClick={(e: any) => e.stopPropagation()}>
                <OpenSeaIcon />
              </CardOpenSeaLink>

              <Mooncourt />
            </CardFooter>
          </CardBody>
        </InnerCardWrapper>
      </StyledCardWrapper>
    </CardContainer>
  );
}
