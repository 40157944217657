import styled, { css, keyframes } from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XL } from '../../styles/Breakpoints';
import { Button } from '../common/Button';

export const Glass = css`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
`;

export const Wrapper = styled.div`
  grid-template-columns: repeat(12, 1fr);
  gap: 1.75rem;
  display: grid;
  width: 100%;
`;

export const Container = styled.section`
  ${Glass};
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  column-gap: 1.625rem;
  padding: 2.5rem 0;
  grid-column: 1 / -1;

  ${BREAKPOINT_MD} {
    padding: 4rem 0;
  }

  ${BREAKPOINT_XL} {
    grid-column: 2 / -2;
  }
`;

export const AppHeadline = styled.h2`
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 1.5rem;

  ${BREAKPOINT_SM} {
    font-size: 1.875rem;
  }

  ${BREAKPOINT_LG} {
    font-size: 2.5rem;
  }
`;

export const CornerFrame = css`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--corner-color, #fff);
    padding: var(--b);
    border-radius: var(--r);
    -webkit-mask: linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50% var(--b) / calc(100% - 2 * var(--w)) 100% repeat-y,
      linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100% calc(100% - 2 * var(--w)) repeat-x,
      linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    -webkit-mask-composite: destination-out;
  }
`;

export const InternalHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.25rem;
  grid-column: 1 / -1;
  padding: 0 1.25rem 1.25rem;
  text-align: center;

  ${BREAKPOINT_SM} {
    grid-column: 2 / -2;
    padding: 0 0 1.25rem;
  }

  ${BREAKPOINT_LG} {
    text-align: start;
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 0.5rem 2rem;
    grid-column: 2 / -2;
  }

  &::after {
    content: '';
    position: absolute;
    width: calc(100% - 1.25rem * 2);
    bottom: 0;
    height: 2px;
    background: #fff;
    box-shadow: 0 0 24px #ffffff;

    ${BREAKPOINT_SM} {
      width: 100%;
    }
  }
`;

export const InternalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.75rem 0.25rem;
  margin-top: 1.5rem;

  ${BREAKPOINT_SM} {
    gap: 0.75rem 1rem;
    justify-content: center;
  }

  ${BREAKPOINT_LG} {
    margin-top: 0;
    align-items: flex-end;
    grid-area: 1 / 2 / 3 / 3;
  }
`;

export const InternalStyledButton = styled(Button)<{ theme?: 'primary' | 'secondary' | 'tertiary' | 'inverted' | undefined }>`
  padding-inline: 1.25rem;
  height: 2.5rem;
  font-size: 0.8125rem;

  ${BREAKPOINT_MD} {
    font-size: 1rem;
  }
`;

export const RefreshButton = styled(InternalStyledButton)`
  width: 2.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const InternalHeadline = styled.h3`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2.5rem;
`;

export const InternalSubHeadline = styled.p`
  margin-top: 0.25rem;

  ${BREAKPOINT_LG} {
    grid-area: 2 / 1;
    margin: 0;
  }
`;

export const InternalItemWrapper = styled.div<{ visible?: boolean }>`
  display: grid;
  gap: 1.25rem;
  grid-column: 1 / -1;

  ${BREAKPOINT_SM} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${BREAKPOINT_LG} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${BREAKPOINT_XL} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${({ visible = true }) =>
    !visible &&
    css`
      content-visibility: hidden;

      @supports not (content-visibility: visible) {
        visibility: hidden;
      }
    `};
`;

const PlaceholderShimmer = keyframes`
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
`;

export const ShimmerLoadingAnimation = css`
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 30%, rgba(255, 255, 255, 0.75) 50%, transparent 70%);
    background-size: 200% 100%;
    animation: 1.5s ${PlaceholderShimmer} infinite forwards linear;
  }
`;
