import { Children, PropsWithChildren } from 'react';
import { NFTPlaceholder, NFTPlaceholderProps } from './NFTPlaceholder';
import { InternalItemWrapper } from './Container';

interface InternalContentGridProps extends PropsWithChildren {
  loading: boolean;
  placeholder: NFTPlaceholderProps;
}

export default function InternalContentGrid(props: InternalContentGridProps) {
  const { children, loading, placeholder } = props;

  return (
    <InternalItemWrapper>
      {loading || Children.count(children) === 0 ? <NFTPlaceholder {...placeholder} loading={loading} /> : children}
    </InternalItemWrapper>
  );
}
