import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAccount } from 'wagmi';
import styled from 'styled-components';
import RefreshIcon from '../../icons/RefreshIcon';
import { NFTPlaceholderProps } from '../NFTPlaceholder';
import { ShopHistoryItemProps, OrderProps, RaffleTicketProps, useShopApi } from '../../../services/shop';
import HistoryItemCard from './HistoryItemCard';
import { Button } from '../../common/Button';
import { InternalHeadline, RefreshButton, Wrapper } from '../Container';
import InternalContentGrid from '../InternalContentGrid';
import { DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { Translations } from '../../../utils/Translations';
import { useIsApiReady } from '../../../services/ApiProvider';

const Headline = styled(InternalHeadline)`
  grid-column: 1 / -1;
`;

const ControlsWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: space-between;
`;

const CategoryWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
`;

const CategoryButton = styled(Button)`
  padding-inline: 0.75rem;
`;

export interface HistoryProps {
  headline: string;
  placeholder: NFTPlaceholderProps;
}

export const History: FC = () => {
  const [items, setItems] = useState<ShopHistoryItemProps[]>([]);
  const [raffles, setRaffles] = useState<RaffleTicketProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeCategory, setActiveCategory] = useState<'shop' | 'raffle' | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const shopApi = useShopApi();
  const { address } = useAccount();
  const isApiReady = useIsApiReady();

  const changeView = ({ matches }: MediaQueryListEvent) => {
    setIsMobile(matches);
  };

  useEffect(() => {
    const view = window.matchMedia(`(max-width: ${DIMEN_BREAKPOINT_MD}px)`);
    setIsMobile(view.matches);
    view.addEventListener('change', changeView);

    return () => {
      view.removeEventListener('change', changeView);
    };
  }, []);

  const populatedItems = useMemo(() => {
    return items.map((item ) => ({
      ...item,
      //...orders.find(({ historyItemId }) => historyItemId === item.id),
    }));
  }, [items]);

  const populatedRaffles = useMemo(() => {
    return raffles.map(({ id, raffle, itemVariant, amount, wallet, createdAt }) => ({
      id,
      itemVariant,
      amount,
      item: raffle.item,
      wallet,
      purchasedAt: createdAt,
      purchased: true,
      redeemed: false,
      redeemedAt: '',
      createdAt: '',
      updatedAt: '',
    }));
  }, [raffles]);

  const filteredItems = useMemo(() => {
    let newItems = [];
    if (activeCategory === 'shop' || activeCategory === null) {
      newItems.push(...populatedItems);
    }
    if (activeCategory === 'raffle' || activeCategory === null) {
      newItems.push(...populatedRaffles);
    }
    return newItems;
  }, [populatedItems, populatedRaffles, activeCategory]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (isApiReady) {
      try {
        const [shopItems, raffleTickets] = await Promise.all([
          shopApi.getShopHistory(address!),
          shopApi.getRaffleTickets(address!),
        ]);
        setItems(shopItems);
        setRaffles(raffleTickets);
      } catch (error) {
        console.log('fetchData error:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [address, isApiReady]);

  function handleCategoryChange(change: null | 'shop' | 'raffle') {
    if (activeCategory === change) {
      setActiveCategory(null);
    } else {
      setActiveCategory(change);
    }
  }

  useEffect(() => {
    if (isApiReady) {
      fetchData();
    }
  }, [fetchData, isApiReady]);

  return (
    <Wrapper>
      <Headline>{Translations.shopHistory.headline}</Headline>

      <ControlsWrapper>
        <CategoryWrapper>
          <CategoryButton theme={activeCategory === null ? 'primary' : 'secondary'} onClick={() => handleCategoryChange(null)}>
            All
          </CategoryButton>

          <CategoryButton theme={activeCategory === 'shop' ? 'primary' : 'secondary'} onClick={() => handleCategoryChange('shop')}>
            Shop
          </CategoryButton>

          <CategoryButton theme={activeCategory === 'raffle' ? 'primary' : 'secondary'} onClick={() => handleCategoryChange('raffle')}>
            Raffle
          </CategoryButton>
        </CategoryWrapper>

        <RefreshButton onClick={fetchData}>
          <RefreshIcon />
        </RefreshButton>
      </ControlsWrapper>

      <InternalContentGrid loading={loading} placeholder={Translations.shopHistory.placeholder}>
        {filteredItems.map((item, index) => (
          <HistoryItemCard key={item.id} {...item} loadLazy={isMobile ? index > 5 : index > 11} />
        ))}
      </InternalContentGrid>
    </Wrapper>
  );
};
