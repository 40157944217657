// General
import { FC, SVGProps } from 'react';

export default function LogoutIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M15.75 5.57143 22 12l-6.25 6.4286H14.5v-3.8572H8.25V9.42857h6.25V5.57143h1.25Zm-7.5 0H4.5V18.4286h5V21H2V3h7.5v2.57143H8.25Z'
      />
    </svg>
  );
}
