import React, { PropsWithChildren } from 'react';
import { FormProvider } from 'react-hook-form';
import { FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form';

interface IFormProps<T extends FieldValues> {
  className?: string;
  form: UseFormReturn<T>;
  onSubmit: (values: T) => void;
  onInvalid?: (values: FieldErrors<T>) => void;
}

export const Form = <T extends FieldValues>(props: PropsWithChildren<IFormProps<T>>) => {
  const {
    className,
    form,
    onSubmit,
    onInvalid,
    children,
  } = props;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, onInvalid)} className={className}>
        {children}
      </form>
    </FormProvider>
  );
};