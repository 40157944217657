// General
import { FC, useContext } from 'react';
import { Connector, useConnect } from 'wagmi';
import styled, { css } from 'styled-components';

// Helpers
import { dispatchWalletConnectingFailedEvent } from '../../../utils/walletConnect/event';
import { WalletConnectTranslations } from '../app/Layout';

// Components
import CoinbaseWallet from '../../icons/CoinbaseIcon';
import Metamask from '../../icons/Metamask';
import WalletConnect from '../../icons/WalletConnectIcon';
import Button from './Button';
import WalletConnectModal, { IModalProps } from './WalletConnectModal';
import Wysiwyg from '../../common/Wysiwyg';

// Styles
import { BREAKPOINT_LG, BREAKPOINT_SM } from '../../../styles/Breakpoints';

const TextStyles = css`
  text-align: center;
  color: #000;
`;

export const Headline = styled.h2`
  ${TextStyles};
  line-height: 1.2;
  font-size: 1.875rem;
  margin-bottom: 1rem;
  font-family: var(--font-family-headline);
  text-transform: uppercase;

  ${BREAKPOINT_LG} {
    font-size: 2.5rem;
  }
`;

export const IntroText = styled(Wysiwyg)`
  ${TextStyles};
  font-family: var(--font-family-default);
  line-height: 1.5;
  max-width: 450px;
  font-size: 1rem;

  &,
  p {
    &:not(:last-child) {
      margin: 0 auto 2rem;

      ${BREAKPOINT_LG} {
        margin: 0 auto 2.5rem;
      }
    }
  }
`;

const WalletConnectWrapper = styled.div`
  display: grid;
  gap: 1rem;
  justify-items: center;
`;

export const WalletButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .875rem;
  padding: 0;
  min-width: 210px;

  ${BREAKPOINT_SM} {
    min-width: 320px;
  }
`;

const WalletIcon = styled.div`
  height: 28px;
  width: 28px;
`;

const ConnectWallet: FC<IModalProps> = ({ close }) => {
  const { connectAsync, connectors, isLoading } = useConnect();
  const t = useContext(WalletConnectTranslations);

  const connect = async (connector: Connector) => {
    try {
      await connectAsync({ connector });
      close();
    } catch (err) {
      dispatchWalletConnectingFailedEvent(err);
    }
  };

  const formatString = (value: string) => {
    return value.replaceAll(' ', '-').toLocaleLowerCase();
  };

  const renderWalletIcon = (name: string) => {
    if (name === 'metamask') {
      return <WalletIcon as={Metamask} aria-hidden='true' />;
    } else if (name === 'coinbase-wallet') {
      return <WalletIcon as={CoinbaseWallet} aria-hidden='true' />;
    } else {
      return <WalletIcon as={WalletConnect} aria-hidden='true' />;
    }
  };

  return (
    <WalletConnectModal close={close}>
      <Headline>{t?.connectHeadline}</Headline>
      <IntroText content={t?.connectText!} />
      <WalletConnectWrapper>
        {connectors.map((connector) => (
          <WalletButton
            theme={'secondary'}
            disabled={!connector.ready || isLoading}
            key={connector.id}
            onClick={() => connect(connector)}
          >
            {renderWalletIcon(formatString(connector.name))}
            {connector.name}
          </WalletButton>
        ))}
      </WalletConnectWrapper>
    </WalletConnectModal>
  );
};

export default ConnectWallet;
