export default function useFeatureToggle() {
  const toBoolean = (val?: string) => val === 'true';

  return {
    REVEAL_ACTIVE: toBoolean(process.env.REACT_APP_FEATURE_REVEAL_ACTIVE),
    TRAINING_ACTIVE: toBoolean(process.env.REACT_APP_FEATURE_TRAINING_ACTIVE),
    SHOP_ACTIVE: toBoolean(process.env.REACT_APP_FEATURE_SHOP_ACTIVE),
    CLAIM_ACTIVE: toBoolean(process.env.REACT_APP_FEATURE_CLAIM_ACTIVE),
    MINT_ACTIVE: toBoolean(process.env.REACT_APP_FEATURE_MINT_ACTIVE),
  };
}
