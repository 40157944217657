import { useRef } from 'react';
import { useDiscordLogin } from 'react-discord-login';

export const useOAuthWindow = () => {
  const childWindow = useRef<Window | null>(null);

  const childWindowConfig = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=600';

  const { buildUrl: buildDiscordUrl } = useDiscordLogin({
    clientId: process.env.REACT_APP_DISCORD_OAUTH_CLIENT_ID!,
    redirectUri: `${process.env.REACT_APP_OAUTH_REDIRECT_BASE_URL}discord`,
    responseType: 'code',
    scopes: ['identify', 'email'],
  });

  const openWindow = (url: string) => {
    const child = window.open(url, 'login', childWindowConfig);
    child?.focus();
    childWindow.current = child;
    return child;
  };

  const closeWindow = (child: Window) => {
    childWindow.current = null;
    child.close();
  };

  return {
    openWindow,
    closeWindow,
    childWindow,
    buildDiscordUrl,
  };

};
