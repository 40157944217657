// Helpers
import { GoogleAnalytics } from './GoogleAnalytics';

// States
import { useAllowedCookies } from '../../states/CookieState';

export const ExternalScripts = () => {
  const allowedCookies = useAllowedCookies();

  return <>{allowedCookies.some((key) => ['performance', 'comfort'].includes(key)) && <GoogleAnalytics />}</>;
};
