import styled from 'styled-components';
import { PropsWithChildren } from 'react';

const Wrapper = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-rows: ${({ open }) => (open ? '1fr' : '0fr')};
  transition: grid-template-rows 0.5s ease-in-out;
  will-change: grid-template-rows, height;
`;

const InnerWrapper = styled.div`
  overflow: hidden;
  will-change: grid-template-row;
`;

interface IAnimatedAccordionProps extends PropsWithChildren {
  open: boolean;
}

export default function AnimatedAccordion(props: IAnimatedAccordionProps) {
  const { open, children } = props;

  return (
    <Wrapper open={open}>
      <InnerWrapper>{children}</InnerWrapper>
    </Wrapper>
  );
}
