import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export interface ITrackingEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

const EmptyState: IGAState = {
  trackPageView: () => {
    console.debug('Tracking Page View', 'not allowed');
  },
  trackEvent: () => {
    console.debug('Tracking Event', 'not allowed');
  },
};

interface IGAState {
  trackPageView: (url: string) => void;
  trackEvent: (props: ITrackingEvent) => void;
}

const GAState = atom<IGAState>({
  key: 'TrackingGAState',
  default: {
    ...EmptyState,
  },
});

export const useInitGA = () => {
  const setState = useSetRecoilState(GAState);
  return (trackingAllowed: boolean) => {
    const trackingId = process.env.REACT_APP_TRACKING_GA_ID || 'G-FZJM1KSW66';

    if (!!trackingId && trackingAllowed) {
      // ReactGA.initialize(trackingId, { testMode: !!(process && process?.env && process?.env.NODE_ENV && process.env.NODE_ENV === 'development') });
      ReactGA.initialize(([
        {
          trackingId: trackingId,
        }
      ]))
      setState({
        trackPageView: (url) => {
          ReactGA.send({ hitType: 'pageview', page: url });
        },
        trackEvent: (props) => {
          ReactGA.event(props);
        },
      });
    } else {
      setState(EmptyState);
    }
  };
};


export const useGA = () => useRecoilValue( GAState);

export const useGAPageViewTracking = () => {
  const ga = useGA();
  useEffect(() => {
    ga.trackPageView(`${window.location.hostname}${window.location.pathname}`);
  }, [ga]);
};
