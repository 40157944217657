import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import SSOButtons from './SSOButtons';
import CustomPasswordValidation, { validatePassword } from './CustomPasswordValidation';
import AccountConfirmation from './AccountConfirmation';
import { useSetRecoilState } from 'recoil';
import Modal from '../../common/Modal';
import { BREAKPOINT_SM } from '../../../styles/Breakpoints';
import Mooncourt from '../../icons/Mooncourt';
import { Form } from '../../common/form/Form';
import { TextInputStyles } from '../../common/form/TextInput';
import { Button } from '../../common/Button';
import LoadingSpinner from '../../common/LoadingSpinner';
import { AuthTokenState } from '../../../states/AppData';
import { useAccountApi } from '../../../services/account';
import ChevronIcon from '../../icons/ChevronIcon';
import { Translations } from '../../../utils/Translations';
import AnimatedAccordion from '../../common/AnimatedAccordion';
import Checkbox from '../../common/form/Checkbox';

const StyledModal = styled(Modal)`
  background: var(--color-text-highlight);
  max-width: 35rem;
  padding: 3rem 1.5rem;

  ${BREAKPOINT_SM} {
    padding: 3rem 5.375rem;
  }
`;

const InnerWrapper = styled.div`
  display: grid;
  gap: 2rem;
`;

const BackButton = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1.5rem;
  transform: rotate(90deg);

  path {
    stroke-width: 2px;
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      color: var(--color-primary);
    }
  }
`;

const Logo = styled(Mooncourt)`
  justify-self: center;
  font-size: 1.5rem;
  color: var(--color-primary);
`;

const Headline = styled.h3`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
`;

const StyledForm = styled(Form)`
  display: grid;
`;

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-block: 1.5rem;
`;

const DividerLine = styled.span`
  height: 1px;
  width: 100%;
  background-color: var(--color-text-default);
`;

const DividerText = styled.p`
  color: var(--color-text-default);
  font-weight: 600;
`;

const CustomInput = styled.input`
  ${TextInputStyles};
  box-shadow: none;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  display: grid;
  gap: 0.75rem;
  margin-block: 1rem;

  label {
    align-items: center;
  }

  svg {
    top: 50%;
    transform: translateY(-50%);
  }
`;

const CheckboxText = styled.p`
  font-size: 0.75rem;
  text-transform: uppercase;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  --width: 4px;
`;

interface ISSOForm {
  email: string;
  password: string;
  repeatedPassword: string;
  messages: boolean;
  terms: boolean;
  sso: string;
}

export type TModalGridType = 'initial' | 'register' | 'login' | 'otp';

interface ILoginModalProps {
  onClose: () => void;
}

export default function LoginModal(props: ILoginModalProps) {
  const { onClose } = props;

  const accountApi = useAccountApi();
  const setAuthTokenState = useSetRecoilState(AuthTokenState);

  const form = useForm();
  const password = form.watch('password');
  const repeatedPassword = form.watch('repeatedPassword');

  const [loading, setLoading] = useState<boolean>(false);
  const [modalGridType, setModalGridType] = useState<TModalGridType>('initial');
  const [otp, setOtp] = useState<string | undefined>(undefined);
  const [account, setAccount] = useState<string | undefined>(undefined);

  const passwordBorderColor = useMemo(() => {
    if (loading) {
      return 'var(--color-grey-700)';
    } else if (modalGridType === 'login' || modalGridType === 'initial' || modalGridType === 'otp') {
      return 'initial';
    } else if (password?.length > 0) {
      if (validatePassword(password)) {
        return 'var(--color-signal-green)';
      } else {
        return 'initial';
      }
    } else {
      return 'initial';
    }
  }, [loading, password, modalGridType]);

  const repeatedPasswordBorderColor = useMemo(() => {
    if (loading) {
      return 'var(--color-grey-700)';
    } else if (repeatedPassword?.length > 0) {
      if (repeatedPassword === password) {
        return 'var(--color-signal-green)';
      } else {
        return 'var(--color-signal-red)';
      }
    } else {
      return 'initial';
    }
  }, [loading, password, repeatedPassword]);

  const handleNavigateBack = useCallback(() => {
    setModalGridType('initial');
    form.resetField('password');
    form.resetField('repeatedPassword');
    form.resetField('terms');
    form.resetField('newsletter');
    setOtp(undefined);
    if (modalGridType === 'otp') {
      form.reset({ email: '' });
    } else {
      form.reset({ email: form.getValues('email') });
    }
  }, [form, modalGridType]);

  const handleRegisteredCheck = useCallback(
    async (formProps: ISSOForm) => {
      try {
        const response = await accountApi.getIsEmailRegistered(formProps.email);
        if (response?.registered) {
          setModalGridType('login');
        } else {
          setModalGridType('register');
        }
        form.reset({ email: formProps.email });
      } catch (error) {
        console.log('error checking if user is registered:', error);
      }
    },
    [form, accountApi],
  );

  const handleLogin = useCallback(
    async (formProps: ISSOForm) => {
      try {
        const data = { email: formProps.email, password: formProps.password };
        const response = await accountApi.signIn(data);
        if (response.status === 412) {
          setAccount(response.data.account);
          setModalGridType('otp');
        } else if (response.status === 200) {
          setAuthTokenState({ token: response.data.token });
          onClose();
        } else {
          // TODO @daniel add error handling
        }
      } catch (error) {
        console.log('error logging in:', error);
      }
    },
    [accountApi, onClose, setAuthTokenState],
  );

  const handleRegister = useCallback(
    async (formProps: ISSOForm) => {
      try {
        const data = {
          email: formProps.email,
          password: formProps.password,
          // messages: formProps.messages TODO @daniel save this information in payload
        };
        const response = await accountApi.signUp(data);
        if (response?.success) {
          setAccount(response.account);
          setModalGridType('otp');
        } else {
          // TODO handle error
        }
      } catch (error) {
        console.log('error registering user:', error);
      }
    },
    [accountApi],
  );

  const handleAccountConfirmation = useCallback(async () => {
    if (account && otp) {
      try {
        const response = await accountApi.completeSignUp({ account, code: otp });
        setAuthTokenState({ token: response.token });
      } catch (error) {
        setOtp(undefined);
        // TODO @daniel add error handling
        console.log('error confirming account:', error);
      }
    }
  }, [accountApi, account, otp, setAuthTokenState]);

  const submitHandler = useCallback(
    async (formProps: any) => {
      setLoading(true);
      switch (modalGridType) {
        case 'initial':
          await handleRegisteredCheck(formProps);
          break;
        case 'login':
          await handleLogin(formProps);
          break;
        case 'register':
          await handleRegister(formProps);
          break;
        case 'otp':
          await handleAccountConfirmation();
          break;
      }
      setLoading(false);
    },
    [modalGridType, handleRegisteredCheck, handleLogin, handleRegister, handleAccountConfirmation],
  );

  const submitButtonText = useMemo(() => {
    switch (modalGridType) {
      case 'initial':
        return 'Sign In';
      case 'login':
        return 'Sign In';
      case 'register':
        return 'Agree and Continue';
      case 'otp':
        return 'Verify';
    }
  }, [modalGridType]);

  const emailInputFormProps = useMemo(() => {
    if (modalGridType === 'initial' || modalGridType === 'login' || modalGridType === 'register') {
      return form.register('email', {
        validate: (value) => (value || '').includes('@'),
        required: true,
      });
    } else {
      form.resetField('email');
    }
  }, [form, modalGridType]);

  const passwordInputFormProps = useMemo(() => {
    if (modalGridType === 'initial') {
      form.resetField('password');
    } else if (modalGridType === 'register') {
      return form.register('password', {
        validate: (value) => /[A-Z]/g.test(value) && /[^\w\d]/g.test(value) && /[0-9]/g.test(value) && /.{8,}$/g.test(value),
        required: true,
      });
    } else if (modalGridType === 'login') {
      return form.register('password', { required: true });
    }
  }, [form, modalGridType]);

  const repeatPasswordInputFormProps = useMemo(() => {
    if (modalGridType === 'register') {
      return {
        ...form.register('repeatedPassword', {
          validate: (value) => value === password,
          required: true,
        }),
      };
    } else {
      form.resetField('repeatedPassword');
    }
  }, [form, modalGridType, password]);

  const isSubmitButtonDisabled = useMemo(() => {
    if (loading) {
      return true;
    } else if (modalGridType === 'otp') {
      return (otp || '').length < 6;
    } else {
      return !form.formState.isValid;
    }
  }, [form.formState, loading, otp, modalGridType]);

  return (
    <StyledModal close={onClose}>
      <InnerWrapper>
        {modalGridType !== 'initial' && (
          <BackButton onClick={handleNavigateBack}>
            <ChevronIcon />
          </BackButton>
        )}

        <Logo />

        <Headline>{Translations.sso.headline}</Headline>

        <StyledForm form={form} onSubmit={submitHandler}>
          <AnimatedAccordion open={modalGridType === 'initial'}>
            <SSOButtons onClose={onClose}/>

            <DividerWrapper>
              <DividerLine />
              <DividerText>or</DividerText>
              <DividerLine />
            </DividerWrapper>
          </AnimatedAccordion>

          <AnimatedAccordion open={modalGridType === 'initial' || modalGridType === 'login' || modalGridType === 'register'}>
            <CustomInput type={'email'} placeholder={'E-Mail'} {...emailInputFormProps} style={{ marginBlockEnd: 12 }} disabled={loading} />
          </AnimatedAccordion>

          <AnimatedAccordion open={modalGridType === 'login' || modalGridType === 'register'}>
            <CustomInput
              type={'password'}
              placeholder={'Password'}
              {...passwordInputFormProps}
              style={{ marginBlockEnd: 12, borderColor: passwordBorderColor }}
              disabled={loading}
            />
          </AnimatedAccordion>

          <AnimatedAccordion open={modalGridType === 'register'}>
            <CustomPasswordValidation value={password} />

            <CustomInput
              {...repeatPasswordInputFormProps}
              type={'password'}
              placeholder={'Repeat Password'}
              style={{ borderColor: repeatedPasswordBorderColor }}
              disabled={loading}
            />

            <CheckboxWrapper>
              <Checkbox
                name={'terms'}
                optional={modalGridType !== 'register'}
                disabled={modalGridType !== 'register' || loading}
                hideErrors>
                <CheckboxText>I have read and agree to the Terms & Conditions and the Privacy Policy.</CheckboxText>
              </Checkbox>

              <Checkbox name={'newsletter'} optional disabled={modalGridType !== 'register' || loading}>
                <CheckboxText>I want to receive messages from mooncourt for marketing purposes.</CheckboxText>
              </Checkbox>
            </CheckboxWrapper>
          </AnimatedAccordion>

          <AnimatedAccordion open={modalGridType === 'otp'}>
            <AccountConfirmation otp={otp} setOtp={setOtp} />
          </AnimatedAccordion>

          <SubmitButton type={'submit'} disabled={isSubmitButtonDisabled}>
            {loading ? <StyledLoadingSpinner /> : submitButtonText}
          </SubmitButton>
        </StyledForm>

        {/*TODO @daniel delete*/}
        {/*<IconButton onClick={dispatchConnectWallet}>Connect wallet</IconButton>*/}
      </InnerWrapper>
    </StyledModal>
  );
}
