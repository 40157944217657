import { Button } from '../../../common/Button';
import Modal from '../../../common/Modal';
import Mooncourt from '../../../icons/Mooncourt';
import styled from 'styled-components';
import PointsIcon from '../../../icons/PointsIcon';
import { ShopItemProps, ShopItemVariant } from '../../../../services/shop';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { FontFamilies } from '../../../../styles/Fonts';
import InfoIcon from '../../../icons/InfoIcon';
import Checkbox from '../../../common/form/Checkbox';
import { Form } from '../../../common/form/Form';
import { useForm } from 'react-hook-form';
import { Translations } from '../../../../utils/Translations';
import Wysiwyg from '../../../common/Wysiwyg';
import { useState } from 'react';
import { DetailPagePurchaseInfo } from './DetailPageStyledComponents';

const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MooncourtIcon = styled(Mooncourt)`
  font-size: 1.5rem;
  color: var(--color-primary);
`;

const Headline = styled.h3`
  font-size: 1.5rem;
  margin-block-end: 2rem;
`;

const Name = styled.h2`
  font-size: 1.75rem;
  line-height: 1.2;
  margin-block-end: 0.75rem;
`;

const Info = styled.p`
  text-transform: capitalize;
`;

const Price = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
  font-size: 1.25rem;
  font-family: ${FontFamilies.headline};
  font-weight: 700;
  margin-block-start: 0.75rem;
`;

const PurchaseInfo = styled(DetailPagePurchaseInfo)`
  margin-block: 2.25rem;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-block-end: 1.25rem;
`;

const Terms = styled(Wysiwyg)`
  margin-block-end: 0;
  font-size: 0.875rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    gap: 1.25rem;
  }
`;

const StyledButton = styled(Button)`
  display: grid;
  place-content: center;
  width: 100%;
`;

interface IDetailPageBuyModalProps {
  close: () => void;
  buy: () => void;
  item: ShopItemProps;
  amount?: number;
  purchaseInfo: string;
  selectedVariant: ShopItemVariant;
}

export default function DetailPageBuyModal(props: IDetailPageBuyModalProps) {
  const { close, buy, item, amount, purchaseInfo, selectedVariant } = props;

  const [loading, setLoading] = useState(false);
  const form = useForm();

  async function submitHandler() {
    setLoading(true);
    try {
      await buy();
    } catch (error) {
      console.log('error buying item / raffle');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal close={close}>
      <FormWrapper form={form} onSubmit={submitHandler}>
        <MooncourtIcon />

        <Headline>complete your order</Headline>

        <Name>{item.name}</Name>

        {amount && <Info>Raffle Tickets: {amount}</Info>}

        <Info>{selectedVariant.attributes.map((attr) => attr.type + ': ' + attr.value).join(' | ')}</Info>

        <Price>
          <PointsIcon /> {(selectedVariant.price || item.price) * (amount || 1)} MOONPOINTS
        </Price>

        <PurchaseInfo>
          <InfoIcon /> <Wysiwyg content={purchaseInfo.replace('|||', '')} />
        </PurchaseInfo>

        <CheckboxWrapper>
          <Checkbox name={'terms'}>
            <Terms content={Translations.detailPage.buyModal.terms} />
          </Checkbox>
        </CheckboxWrapper>

        <ButtonWrapper>
          <StyledButton theme={'secondary'} onClick={close}>
            Cancel
          </StyledButton>

          <StyledButton theme={'primary'} type={'submit'} disabled={!form.watch('terms') || loading}>
            Buy
          </StyledButton>
        </ButtonWrapper>
      </FormWrapper>
    </Modal>
  );
}
