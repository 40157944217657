import { SVGProps } from 'react';

export default function ShopIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 27 24' {...props}>
      <path
        fill='currentColor'
        d='M1.58333.662109H.5V2.82878H3.93507L6.65694 17.1152l.16702.8802H23.6111v-2.1666H8.62049l-.41077-2.1667H22.8889l2.934-9.38888.6771-2.16667H6.00694l-.10833-.56423L5.7316.662109H1.58333ZM7.79444 11.4954 6.41771 4.27322H23.5524l-2.2569 7.22218H7.79444Zm.65 12.2778c.57464 0 1.12574-.2283 1.53207-.6346.40629-.4063.63459-.9574.63459-1.532 0-.5747-.2283-1.1258-.63459-1.5321-.40633-.4063-.95743-.6346-1.53207-.6346-.57463 0-1.12573.2283-1.53206.6346-.40633.4063-.6346.9574-.6346 1.5321 0 .5746.22827 1.1257.6346 1.532.40633.4063.95743.6346 1.53206.6346Zm15.16666-2.1666c0-.5747-.2283-1.1258-.6346-1.5321-.4063-.4063-.9574-.6346-1.5321-.6346-.5746 0-1.1257.2283-1.532.6346-.4063.4063-.6346.9574-.6346 1.5321 0 .5746.2283 1.1257.6346 1.532.4063.4063.9574.6346 1.532.6346.5747 0 1.1258-.2283 1.5321-.6346.4063-.4063.6346-.9574.6346-1.532Z'
      />
    </svg>
  );
}
