import { FC, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import Wallet from '../../icons/Wallet';
import { useAccount } from 'wagmi';
import { WalletConnectTranslations } from '../app/Layout';
import LinkHandler from '../../common/LinkHandler';
import { useSetRecoilState } from 'recoil';
import { GlobalAddressModalState } from '../../../states/GlobalAddressModalState';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import ChevronIcon from '../../icons/ChevronIcon';
import { Button } from '../../common/Button';

const Wrapper = styled.div<{ isConnected: boolean }>`
  position: relative;
  display: flex;

  &::before {
    content: '';
    z-index: 2;
    position: absolute;
    top: -0.25rem;
    left: -0.25rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 100%;
    background-color: var(--color-signal-${({ isConnected }) => (isConnected ? 'green' : 'red')});
    pointer-events: none;

    ${BREAKPOINT_MD} {
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
    }
  }
`;

const WalletIconButton = styled(Button)`
  display: contents;
  font-size: 1.125rem;

  svg {
    z-index: 1;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary);
    }
  }

  ${BREAKPOINT_MD} {
    display: flex;
    padding-inline-start: 2rem;
  }
`;

const StyledWalletIcon = styled(Wallet)`
  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const StyledChevronIcon = styled(ChevronIcon)<{ open: boolean }>`
  display: none;
  transform: rotate(${({ open }) => (open ? -180 : 0)}deg);
  transition: transform 0.25s ease-in-out;

  ${BREAKPOINT_MD} {
    display: inherit;
  }

  path {
    stroke-width: 2px;
  }
`;

const ButtonText = styled.p`
  display: none;

  ${BREAKPOINT_MD} {
    display: inherit;
  }
`;

const WalletMenu = styled.div`
  position: absolute;
  top: -0.75rem;
  right: -1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-black-900);
  padding-inline: 1rem;
  border-radius: 0.625rem;
  border: 1px solid var(--color-text-default);

  ${BREAKPOINT_MD} {
    top: calc(100% + 0.625rem);
    right: 0;
    min-width: 100%;
  }
`;

const MenuItem = styled.div`
  padding-block: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  font-family: var(--font-family-headline);
  padding-inline-end: 2rem;
  display: flex;
  width: 100%;
  white-space: nowrap;

  &:not(:last-child) {
    border-block-end: 1px solid var(--color-grey-800);
  }

  ${BREAKPOINT_MD} {
    &:first-child {
      display: none;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary);
      text-decoration: none;
    }
  }
`;

interface IWalletConnectDropDownProps {
  connect: () => void;
  disconnect: () => void;
}

export const WalletConnectDropDown: FC<IWalletConnectDropDownProps> = (props) => {
  const { connect, disconnect } = props;
  const { isConnected, address } = useAccount();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const t = useContext(WalletConnectTranslations);
  const setOpenAddressModal = useSetRecoilState(GlobalAddressModalState);

  const buttonText = useMemo(() => {
    return isConnected ? `${address?.slice(0, 4)}...${address?.slice(-4, address.length)}` : t?.connectButton;
  }, [isConnected, t, address]);

  function toggleMenu() {
    setMenuOpen((state) => !state);
  }

  function toggleAddressModal() {
    setMenuOpen(false);
    setOpenAddressModal(true);
  }

  function disconnectHandler() {
    setMenuOpen(false);
    disconnect();
  }

  return (
    <Wrapper isConnected={!!address}>
      <WalletIconButton onClick={isConnected ? toggleMenu : connect}>
        <StyledWalletIcon />

        <ButtonText>{buttonText}</ButtonText>

        {isConnected && <StyledChevronIcon open={menuOpen} />}
      </WalletIconButton>

      {menuOpen && (
        <WalletMenu>
          <MenuItem as={'button'} onClick={() => setMenuOpen(false)}>
            {buttonText}
          </MenuItem>

          <MenuItem as={'button'} onClick={toggleAddressModal}>
            Address
          </MenuItem>

          <MenuItem as={LinkHandler} to={'/shop/history'} onClick={() => setMenuOpen(false)}>
            History
          </MenuItem>

          <MenuItem as={'button'} onClick={disconnectHandler}>
            Disconnect
          </MenuItem>
        </WalletMenu>
      )}
    </Wrapper>
  );
};
