import { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useWaitForTransaction } from 'wagmi';
import { ToastContentProps, toast } from 'react-toastify';
import { TTransactionType } from '../../../states/TransactionState';
import { Notifications } from '../../layout/app/Layout';
import { TNotification } from '../../../types/page';
import Toast, { ToastHeadline, ToastContent } from './Toast';

const TransactionId = styled.p`
  font-size: 0.4125rem;
  line-height: 1;
  color: #bbb;
  margin-block-start: 0.5rem;
`;

/**
 * General interface for transaction toasts
 */
export interface ITransactionToastProps {
  txHash: `0x${string}`;
  transactionType: TTransactionType;
}

/**
 * Properties passed into a transaction toast including the properties passed by react-toastify
 */
interface ITransactionToastPropsProps extends ITransactionToastProps, Partial<ToastContentProps> {}

/**
 * Transaction toast that handles transaction watching
 */
export default function TransactionToast(props: ITransactionToastPropsProps) {
  const { txHash, transactionType, closeToast, toastProps } = props;
  const { isSuccess: txSuccess, isError: txError, isLoading: txLoading } = useWaitForTransaction({ hash: txHash });
  const notifications = useContext(Notifications);

  const injectEtherscanLink = useCallback(
    (text: string) => {
      const content = `<a href='${process.env.REACT_APP_TRANSACTION_BASE_URL}/${txHash}' target='_blank'>etherscan</a>`;
      return text.replaceAll('${ETHERSCAN}', content);
    },
    [txHash],
  );

  const getContent = useCallback(
    (props: TNotification) => ({
      headline: props.headline,
      text: injectEtherscanLink(props.text),
    }),
    [injectEtherscanLink],
  );

  const content = useMemo(() => {
    if (txSuccess) {
      if (transactionType === 'mint') {
        return getContent(notifications!.successfulMintNotification);
      } else if (transactionType === 'reveal') {
        return getContent(notifications!.successfulRevealNotification);
      } else if (transactionType === 'claim') {
        return {
          headline: 'CLAIM SUCCESSFUL',
          text: `<p>Your claim was successful. Head over to <a href='/inventory' target='_self'>inventory</a> to view your claimed comets or head over to <a href='${process.env.REACT_APP_TRANSACTION_BASE_URL}/${txHash}' target='_blank'>etherscan</a> to take a look at your transaction</p>`,
        };
      }
    } else if (txError) {
      if (transactionType === 'mint') {
        return getContent(notifications!.failedMintNotification);
      } else if (transactionType === 'reveal') {
        return getContent(notifications!.failedRevealNotification);
      } else if (transactionType === 'claim') {
        return {
          headline: 'CLAIM FAILED',
          text: `<p>Unfortunately your claim failed. To see what went wrong inspect the transaction on <a href='${process.env.REACT_APP_TRANSACTION_BASE_URL}/${txHash}' target='_blank'>etherscan</a> and try again</p>`,
        };
      }
    } else if (txLoading) {
      if (transactionType === 'mint') {
        return getContent(notifications!.pendingMintNotification);
      } else if (transactionType === 'reveal') {
        return getContent(notifications!.pendingRevealNotification);
      } else if (transactionType === 'claim') {
        return {
          headline: 'CLAIM PENDING',
          text: `<p>Your claim is currently pending, you can take a look at your transaction on <a href='${process.env.REACT_APP_TRANSACTION_BASE_URL}/${txHash}' target='_blank'>etherscan</a></p>`,
        };
      }
    }
  }, [txSuccess, txError, txLoading, transactionType, getContent, notifications]);

  useEffect(() => {
    if (txSuccess && toastProps?.toastId) {
      toast.update(toastProps?.toastId, {
        autoClose: 5000,
      });
    }
  }, [txSuccess, toastProps?.toastId]);

  return (
    <Toast closeToast={closeToast} type={'info'}>
      <ToastHeadline>{content!.headline}</ToastHeadline>
      <ToastContent content={content!.text} />
      <TransactionId>{txHash}</TransactionId>
    </Toast>
  );
}
