// General
import styled from 'styled-components';

// Types
import { PropsWithChildren } from 'react';

// Styles
import { BREAKPOINT_LG } from '../../styles/Breakpoints';

const Wrapper = styled.div`
  background: var(--color-black-900);
  color: var(--color-grey-300);
  display: flex;
  gap: 0.5rem;
  height: 2.75rem;
  width: fit-content;
  align-items: center;
  padding-inline: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  border: 1px solid var(--color-grey-800);
  border-radius: 3.125rem;
  white-space: nowrap;

  ${BREAKPOINT_LG} {
    font-size: 1rem;
  }

  svg {
    font-size: 1.25rem;
  }
`;

interface IChipProps extends PropsWithChildren {
  //
}

export default function Chip(props: IChipProps) {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
}
