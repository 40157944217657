import { FC, SVGProps } from 'react';

export default function ProfileIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 23 26' {...props}>
      <path
        fill='currentColor'
        d='M5.6875 8.48047c0-3.43789 2.6457-6.04297 5.6875-6.04297s5.6875 2.60508 5.6875 6.04297c0 1.72153-.9293 3.52423-2.341 5.20003-1.1832 1.4066-2.5188 2.5086-3.3465 3.1281-.8277-.6195-2.16328-1.7266-3.34648-3.1281C6.6168 12.0047 5.6875 10.207 5.6875 8.48047ZM11.375 0C6.88594 0 3.25 3.79844 3.25 8.48047c0 5.12893 5.41328 9.39453 7.0687 10.57773.3098.2184.6805.3301 1.0563.3301s.7465-.1067 1.0563-.3301C14.0867 17.875 19.5 13.6145 19.5 8.48047 19.5 3.79844 15.8641 0 11.375 0ZM0 24.7c0 .716.583984 1.3 1.3 1.3h20.15c.716 0 1.3-.584 1.3-1.3 0-3.6258-2.8285-6.5914-6.4035-6.8098-1.1477 1.1223-2.2344 1.9602-2.8793 2.4223h2.4527c2.0313 0 3.7375 1.3762 4.2403 3.25H2.58477c.50273-1.8738 2.20898-3.25 4.24023-3.25h2.45273c-.64492-.4672-1.72656-1.3-2.87929-2.4223C2.82852 18.1086 0 21.0742 0 24.7Zm10.5625-13.3809c0-1.53863-1.24922-2.78785-2.78789-2.78785h-.8125c-.25391 0-.46211.2082-.46211.46211 0 1.53864 1.24922 2.78784 2.78789 2.78784h.81251c.2539 0 .4621-.2082.4621-.4621Zm4.4129-2.78785c-1.5387 0-2.7879 1.24922-2.7879 2.78785 0 .2539.2082.4621.4621.4621h.8125c1.5387 0 2.7879-1.2492 2.7879-2.78784 0-.25391-.2082-.46211-.4621-.46211h-.8125Z'
      />
    </svg>
  );
}
