import { useApi } from './ApiProvider';

export const useNewsApi = () => {
  const apiInstance = useApi();

  const getNews = async () => {
    return (await apiInstance.get('/v2/content/news')).data;
  };

  return {
    getNews,
  };
};
