import React, { FC, Fragment } from 'react';
import escapeHTML from 'escape-html';
import { Text } from 'slate';

export const PayloadWysiwyg: FC<{ content: any }> = ({ content }) => {
  return content?.map((node: any, i: number) => {
    if (Text.isText(node)) {
      let text = <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />;

      if ((node as any).bold) {
        text = (
          <strong key={i}>
            {text}
          </strong>
        );
      }

      if ((node as any).code) {
        text = (
          <code key={i}>
            {text}
          </code>
        );
      }

      if ((node as any).italic) {
        text = (
          <em key={i}>
            {text}
          </em>
        );
      }

      // Handle other leaf types here...

      return (
        <Fragment key={i}>
          {text}
        </Fragment>
      );
    }

    if (!node) {
      return null;
    }

    switch (node.type) {
      case 'h1':
        return (
          <h1 key={i}>
            <PayloadWysiwyg content={node.children} />
          </h1>
        );
      case 'h2':
        return (
          <h2 key={i}>
            <PayloadWysiwyg content={node.children} />
          </h2>
        );
      case 'h3':
        return (
          <h3 key={i}>
            <PayloadWysiwyg content={node.children} />
          </h3>
        );
      case 'h4':
        return (
          <h4 key={i}>
            <PayloadWysiwyg content={node.children} />
          </h4>
        );
      case 'h5':
        return (
          <h5 key={i}>
            <PayloadWysiwyg content={node.children} />
          </h5>
        );
      case 'h6':
        return (
          <h6 key={i}>
            <PayloadWysiwyg content={node.children} />
          </h6>
        );
      case 'blockquote':
        return (
          <blockquote key={i}>
            <PayloadWysiwyg content={node.children} />
          </blockquote>
        );
      case 'ul':
        return (
          <ul key={i}>
            <PayloadWysiwyg content={node.children} />
          </ul>
        );
      case 'ol':
        return (
          <ol key={i}>
            <PayloadWysiwyg content={node.children} />
          </ol>
        );
      case 'li':
        return (
          <li key={i}>
            <PayloadWysiwyg content={node.children} />
          </li>
        );
      case 'link':
        return (
          <a
            href={escapeHTML(node.url)}
            key={i}
          >
            <PayloadWysiwyg content={node.children} />
          </a>
        );

      default:
        return (
          <p key={i}>
            <PayloadWysiwyg content={node.children} />
          </p>
        );
    }
  });
};

