import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { ShopItemImageProps } from '../../../../services/shop';
import Pagination from '../../../common/Pagination';
import { HideScrollbarStyles } from '../../../../styles/Custom';
import { BREAKPOINT_MD, BREAKPOINT_XXL } from '../../../../styles/Breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-block-end: 1.5rem;

  ${BREAKPOINT_MD} {
    margin-block-end: 0;
  }

  ${BREAKPOINT_XXL} {
    grid-column: 1 / 13;
  }
`;

const ImageSlider = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  background-color: var(--color-grey-600);
  ${HideScrollbarStyles};

  ${BREAKPOINT_MD} {
    display: grid;
    gap: 0.125rem;
    overflow: visible;
    scroll-snap-type: none;
    background: none;
    aspect-ratio: auto;
  }

  ${BREAKPOINT_XXL} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  aspect-ratio: 1 / 1;
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: center;

  img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }

  ${BREAKPOINT_MD} {
    overflow: hidden;
  }
`;

const StyledPagination = styled(Pagination)`
  ${BREAKPOINT_MD} {
    display: none;
  }
`;

interface IDetailImageGalleryProps {
  images: {
    id: string;
    image: ShopItemImageProps;
  }[];
}

// TODO @daniel add fullscreen gallery

export default function DetailImageGallery(props: IDetailImageGalleryProps) {
  const { images } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const io = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentIndex(parseInt(entry.target.id));
        }
      });
    },
    {
      root: sliderRef.current,
      rootMargin: '0px',
      threshold: 0.75,
    },
  );

  useEffect(() => {
    sliderRef.current!.childNodes.forEach((item) => io.observe(item as Element));

    return () => io.disconnect();
  }, []);

  function handleIndexChange(index: number) {
    sliderRef.current?.scrollTo({ left: (sliderRef.current?.scrollWidth / images.length) * index, behavior: 'smooth' });
  }

  return (
    <Wrapper>
      <ImageSlider ref={sliderRef}>
        {images.map(({ image }, index) => (
          <ImageWrapper key={image.id} id={index.toString()}>
            <img src={image.url} alt={image.alt} />
          </ImageWrapper>
        ))}
      </ImageSlider>

      <StyledPagination currentIndex={currentIndex} changeIndex={handleIndexChange} amount={images.length} />
    </Wrapper>
  );
}
