import { SVGProps } from 'react';

export default function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width='188' height='54'
      viewBox='0 0 188 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1028_5047)'>
        <path
          d='M188 13.1465H181.373L178.801 20.064H166.85L164.725 26.9816H170.713H170.741L172.586 33.9014L172.941 35.284H179.594L179.241 33.9014L177.394 26.9816H180.043L181.302 30.4426H184.644L183.357 26.9816L185.482 20.064L186.709 16.6919L188 13.2332L187.97 13.231L188 13.1465Z'
          fill='currentColor'
        />
        <path
          d='M172.947 36.6689L171.844 40.9079L159.402 26.9817L164.251 24.2142L166.374 17.2967H151.084L149.797 13.8379H146.483L147.744 17.2967L144.764 26.9817L149.19 43.5865L148.762 45.2292H129.934L130.344 46.6807L130.939 48.7768L131.439 50.5418L132.313 54.0006H135.653L134.753 50.5418L154.042 50.5062L155.817 43.5865L151.417 26.9817L152.272 24.2142H156.266L151.417 26.9817L166.252 43.5865H171.147H174.237H177.882L178.782 40.1277H178.801L179.682 36.6711V36.6689H172.947Z'
          fill='currentColor'
        />
        <path
          d='M143.432 26.9815L146.412 17.2965H139.759L136.779 26.9815L139.36 36.6687H134.036L131.456 26.9815L136.559 10.3789H129.906L124.803 26.9815L129.228 43.5863H147.86L143.432 26.9815Z'
          fill='currentColor'
        />
        <path
          d='M101.067 36.6686L98.4862 26.9813L101.466 17.2963H106.49L108.613 10.3788L106.849 3.45898H100.222L101.997 10.3788H96.9363L91.8333 26.9813L95.722 41.5789L96.2586 43.5861H121.395L119.593 50.5059H126.248L128.023 43.5861L126.176 36.6686H101.067Z'
          fill='currentColor'
        />
        <path
          d='M108.196 3.45877L109.945 10.3763L104.842 26.9811L107.027 35.2835H125.658L123.473 26.9811L128.576 10.3763H116.626L114.826 3.45877H111.538L110.638 0H107.324L108.198 3.45877H108.196ZM116.707 26.9811L117.156 28.3659H111.833L111.384 26.9811L114.473 17.2961H119.796L116.707 26.9811Z'
          fill='currentColor'
        />
        <path
          d='M117.415 21.7928L118.484 18.334H115.17L114.075 21.7928H117.415Z'
          fill='currentColor'
        />
        <path
          d='M119.681 45.2285H96.714L97.3083 48.0582L97.4601 48.7762L97.8321 50.5412H114.736L113.836 53.9999H117.175L118.693 48.6895L119.681 45.2285Z'
          fill='currentColor'
        />
        <path
          d='M85.2722 10.3785H78.6171L78.6235 10.3919L84.1242 26.9811L83.0595 31.1334L78.8009 26.9811L73.296 10.3785L75.9426 0H72.629L71.7289 3.45877H68.4431L66.6409 10.3785L72.148 26.9811L67.8616 43.5859L69.3837 50.5057H69.3901L69.3837 50.5412H84.8147L85.2401 48.774H85.3278L86.091 45.3153H86.0717L86.091 45.2286H74.863L74.4889 43.5859H74.5167L76.646 35.2835L80.9046 39.4358L79.8399 43.5859H86.4929L86.495 43.5748L90.7771 26.9811L85.2722 10.3785Z'
          fill='currentColor'
        />
        <path
          d='M63.3465 45.2118H26.8984H26.8898L26.4601 43.5691L30.7722 26.9643L29.6241 23.5055H29.6263L28.4932 20.0979L28.4761 20.0467H12.5342L9.96022 13.127H3.3329L4.56644 16.6746L8.14947 26.9643L4.72678 40.2325H1.80007L0 47.1501H5.96245L6.89455 43.5691H10.5182L11.3798 40.2325L14.8046 26.9643H16.1343L11.8501 43.5691H11.8522H18.5031L18.8515 42.222L22.7873 26.9643H24.1192L19.8349 43.5691L21.6093 50.4866L61.3091 50.5244L60.4091 53.9832H63.7484L64.6228 50.5244L63.3465 45.2118Z'
          fill='currentColor'
        />
        <path
          d='M32.104 26.9627L27.8197 43.5675H46.4511L50.7353 26.9627L46.8102 14.8281H24.8652L25.9962 18.2869H29.297L32.1018 26.9627H32.104ZM41.5789 36.6477H36.2557L38.665 26.9627L37.1279 21.7479H42.4512L43.9883 26.9627L41.5789 36.6477Z'
          fill='currentColor'
        />
        <path
          d='M65.1894 10.359L66.9381 3.44141H60.3108L58.5107 10.359H46.5602L47.6911 13.8177H47.7061L52.0651 26.9637L47.7809 43.5685H66.4122L70.6965 26.9637L65.1894 10.359ZM61.5401 36.6488H56.2168L58.5813 26.9637L55.5092 17.2787H60.8324L63.9045 26.9637L61.5401 36.6488Z'
          fill='currentColor'
        />
        <path
          d='M57.0013 18.6602L58.1344 22.1212H61.4737L60.315 18.6602H57.0013Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_1028_5047'>
          <rect width='188' height='54' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  );
}