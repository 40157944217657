// General
import { atom, useResetRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

// Types
import { INFT } from '../types/NFT';
import { RaffleTicketProps, ShopHistoryItemProps } from '../services/shop';
import { IAccount } from '@m3ta-collective/mooncourt-utilities-library';

// Effects
import { TimeStampEffect } from './effects/TimeStampEffect';
import { IUserAddress } from '../types/address';

const { persistAtom } = recoilPersist();

interface ITimeEffect {
  timeStamp?: number;
}

interface INFTState extends ITimeEffect {
  nfts: Array<INFT>;
}

interface IShopItemState extends ITimeEffect {
  items: ShopHistoryItemProps[];
}

interface IRaffleTicketState extends ITimeEffect {
  items: RaffleTicketProps[];
}

interface IConnectedWallet {
  wallet: string;
  ballers:number;
  comets: number;
  booster: number;
}

// Boosters of the user
export const BoosterState = atom<INFTState>({
  key: 'booster-state',
  default: {
    nfts: [],
  },
  effects: [TimeStampEffect],
});

// Ballers of the user
export const BallerState = atom<INFTState>({
  key: 'baller-state',
  default: {
    nfts: [],
  },
  effects: [TimeStampEffect],
});

export const CometsState = atom<INFTState>({
  key: 'comets-state',
  default: {
    nfts: [],
  },
  effects: [TimeStampEffect],
});

// ShopItems of the use
export const ShopItemState = atom<IShopItemState>({
  key: 'shop-item-state',
  default: {
    items: [],
  },
  effects: [TimeStampEffect],
});

export const RaffleTicketState = atom<IRaffleTicketState>({
  key: 'raffle-ticket-state',
  default: {
    items: [],
  },
  effects: [TimeStampEffect],
});

export const AuthTokenState = atom<{ token: string | null }>({
  key: 'auth-token-state',
  default: {
    token: null,
  },
  effects: [persistAtom],
});

export const AccountState = atom<IAccount | null>({
  key: 'account-state',
  default: null,
});

export const WalletState = atom<IConnectedWallet[]>({
  key: 'wallet-state',
  default: [],
});

export const AddressState = atom<IUserAddress | null>({
  key: 'address-state',
  default: null,
});

export const useResetUserState = function () {
  const resetBoosterState = useResetRecoilState(BoosterState);
  const resetBallerState = useResetRecoilState(BallerState);
  const resetCometState = useResetRecoilState(CometsState);
  const resetShopItemState = useResetRecoilState(ShopItemState);
  const resetRaffleTicketState = useResetRecoilState(RaffleTicketState);
  const resetAddressState = useResetRecoilState(AddressState);
  const resetAuthTokenState = useResetRecoilState(AuthTokenState);
  const resetWalletState = useResetRecoilState(WalletState);

  return function () {
    resetBoosterState();
    resetBallerState();
    resetCometState();
    resetShopItemState();
    resetRaffleTicketState();
    resetAddressState();
    resetAuthTokenState();
    resetWalletState();
  };
};
