import styled from 'styled-components';
import { BREAKPOINT_MD, BREAKPOINT_XL } from '../../styles/Breakpoints';
import { Button } from '../common/Button';
import LinkHandler from '../common/LinkHandler';
import { FC } from 'react';

const Wrapper = styled.div`
    display: grid;
    height: 100%;
    place-content: center;

    ${BREAKPOINT_XL} {
        padding-inline-end: 8.5rem;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 3rem;
`;

const Headline = styled.h1`
    color: #000000;
    text-shadow: 0 0 24px #FF1F00, 0 0 3px #FF0F00, 0 0 5px #FE4C00;
    font-size: 3.5rem;
    line-height: 5.5rem;

    ${BREAKPOINT_MD} {
        font-size: 7.5rem;
        line-height: 11rem;
    }
`;

const SubHeadline = styled.h3`
    font-weight: 700;
    text-shadow: 0 0 2px rgba(255, 100, 0, 0.8), 0 0 10px rgba(255, 100, 0, 0.7);
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-block-end: 2rem;

    ${BREAKPOINT_MD} {
        font-size: 2rem;
        line-height: 3rem;
        margin-block-end: 1.5rem;
    }
`;

const StyledLinkHandler = styled(LinkHandler)`
    &:hover {
        text-decoration: none;
    }
`;

const Page404: FC = () => {
 return (
  <Wrapper>
   <ContentWrapper>
    <Headline>{404}</Headline>

    <SubHeadline>PAGE NOT FOUND</SubHeadline>

    <StyledLinkHandler to={'/'}>
     <Button theme={'secondary'}>HOME</Button>
    </StyledLinkHandler>
   </ContentWrapper>
  </Wrapper>
 );
};

export default Page404;
