import styled from 'styled-components';
import { useMemo, useRef, useState } from 'react';
import LinkHandler from '../../common/LinkHandler';
import Burger from './Burger';
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import IconLink from '../../common/IconLink';
import { Body2 } from '../../../styles/FontStyles';
import { NavigationIconLinks } from '../app/Navigation';
import { useRecoilValue } from 'recoil';
import Chip from '../../common/Chip';
import PointsIcon from '../../icons/PointsIcon';
import Level from '../../icons/Level';
import Dialog from '../../common/Dialog';
import { Translations } from '../../../utils/Translations';
import InfoIcon from '../../icons/InfoIcon';
import { AccountState } from '../../../states/AppData';

const Wrapper = styled.nav<{ open: boolean }>`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100 * var(--vh));
  transform: translateX(${({ open }) => (open ? '0' : '100%')});
  transition: transform 450ms ease;
  pointer-events: auto;
  padding-block-start: var(--nav-height);
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(0.625rem);

  ${BREAKPOINT_MD} {
    padding-block: 6rem 1.5rem;
  }
`;

const OverflowWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5rem;
  overflow: auto;
  padding-inline: var(--content-indent);
  padding-block-end: var(--content-indent);
`;

const WalletInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 0.75rem;
  place-content: stretch;
  margin-block-start: 1rem;

  > * {
    width: 100%;
    justify-content: center;
  }

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const SocialItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 2rem;
  border-radius: 0.625rem;
  gap: 2rem;

  ${BREAKPOINT_SM} {
    padding-inline: 3.5rem;
    width: max-content;
    align-self: center;
  }
`;

const NavItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin-block: auto;

  ${BREAKPOINT_SM} {
    align-self: center;
  }
`;

const NavItem = styled.p`
  position: relative;
  font-family: var(--font-family-headline);
  line-height: 1.5;
  font-weight: 700;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: clamp(1.875rem, -1.173rem + 7.31vh, 3.625rem);
  text-transform: uppercase;

  a {
    @media (hover: hover) {
      :hover {
        text-decoration: none;
        -webkit-text-stroke: 1px #f86259;
        color: black;

        :before {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 100%;
          background: radial-gradient(46.27% 46.27% ellipse at center, rgba(198, 44, 30, 0.73) 0%, rgba(253, 63, 32, 0) 100%),
            radial-gradient(46% 12% ellipse at center, rgba(198, 44, 30, 0.3) 0%, rgba(253, 63, 32, 0) 100%),
            radial-gradient(46% 5% ellipse at center, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }
  }
`;

const FooterItemsWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem 1.5rem;
`;

const FooterLink = styled(LinkHandler)`
  ${Body2};
  font-family: var(--font-family-headline);
`;

const BurgerNotification = styled.div<{ open: boolean }>`
  position: relative;
  z-index: 10;
`;

const LevelDialogButton = styled.button`
  display: flex;
  font-size: 1.25rem;
`;

interface INavigationProps {
  className?: string;
}

const navItems = [
  {
    title: 'Home',
    href: '/',
    target: '_self',
  },
  {
    title: 'Inventory',
    href: '/inventory',
    target: '_self',
  },
  {
    title: 'Training',
    href: '/training',
    target: '_self',
  },
  {
    title: 'Shop',
    href: '/shop',
    target: '_self',
  },
];

const footerLinks = [
  {
    title: 'Privacy Policy',
    href: 'https://mooncourt.xyz/privacy',
    target: '_blank',
  },
  {
    title: 'Imprint',
    href: 'https://mooncourt.xyz/imprint',
    target: '_blank',
  },
  {
    title: 'Terms and conditions',
    href: 'https://mooncourt.xyz/terms-and-conditions',
    target: '_blank',
  },
];

export default function Navigation(props: INavigationProps) {
  const { className } = props;
  const [navOpen, setNavOpen] = useState<boolean>(false);

  const dialogRef = useRef<HTMLDialogElement>(null);
  const accountState = useRecoilValue(AccountState);

  function toggleDialog() {
    if (dialogRef?.current) {
      dialogRef.current.open ? dialogRef.current.close() : dialogRef.current.showModal();
    }
  }

  return (
    <>
      <BurgerNotification open={navOpen}>
        <Burger open={navOpen} setOpen={setNavOpen} className={className} />
      </BurgerNotification>

      <Wrapper open={navOpen}>
        <OverflowWrapper>
          <WalletInfoWrapper>
            <Chip>
              <PointsIcon />
              {accountState?.account?.points}
            </Chip>

            <Chip>
              <Level />
              Level {accountState?.account?.level}
              <LevelDialogButton onClick={toggleDialog}>
                <InfoIcon />
              </LevelDialogButton>
            </Chip>
          </WalletInfoWrapper>

          <NavItemsWrapper>
            {navItems?.map((item, index) => (
              <NavItem key={index}>
                <LinkHandler to={item.href} {...item} onClick={() => setNavOpen(false)}>
                  {item.title}
                </LinkHandler>
              </NavItem>
            ))}
          </NavItemsWrapper>

          <SocialItemsWrapper>{NavigationIconLinks?.map((item, index) => <IconLink {...item} key={index} />)}</SocialItemsWrapper>

          <FooterItemsWrapper>
            {footerLinks?.map((link, index) => (
              <FooterLink key={index} to={link.href} {...link}>
                {link.title}
              </FooterLink>
            ))}
          </FooterItemsWrapper>
        </OverflowWrapper>

        <Dialog ref={dialogRef} translation={Translations.tooltip.level} toggleDialog={toggleDialog} />
      </Wrapper>
    </>
  );
}
