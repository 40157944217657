import { useApi } from './ApiProvider';

export const useDashboardApi = () => {
  const apiInstance = useApi();
  const getStats = async () => {
    return (await apiInstance.get(`/v2/account/user/stats`)).data;
  };

  const getDashboardData = async () => {
    return (await apiInstance.get(`/v2/content/dashboard`)).data;
  };

  return {
    getStats,
    getDashboardData,
  };
};
