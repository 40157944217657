import { css } from 'styled-components';

export const FontFaces = css`
  @font-face {
    font-family: "Oswald";
    font-weight: 700;
    src: url("/fonts/Oswald-Bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Oswald";
    font-weight: 400;
    src: url("/fonts/Oswald-Regular.woff2") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 700;
    src: url("/fonts/Inter-Bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 400;
    src: url("/fonts/Inter-Regular.woff2") format("woff2");
  }
`;

const FONT_INTER = 'Inter, sans-serif';
const FONT_OSWALD = 'Oswald, sans-serif';

export const FontFamilies = {
  default: 'var(--font-family-default)',
  headline: 'var(--font-family-headline)',
};

export const FontTheme = css`
  :root {
    --font-family-default: ${FONT_INTER};
    --font-family-headline: ${FONT_OSWALD};
  }
`;