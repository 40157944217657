import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createConfig, WagmiConfig } from 'wagmi';
import { connectors, publicClient, webSocketPublicClient } from './utils/walletConnect/connectors';
import { ApiProvider } from './services/ApiProvider';

/**
 * WAGMI client used as host for multiple
 * hooks to check the current wallet status
 */

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>
  </React.StrictMode>,
);
