import { SVGProps } from 'react';

export default function PointsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 24 24' fill='none'>
      <path
        d='M20.125 12C20.125 9.84512 19.269 7.77849 17.7452 6.25476C16.2215 4.73102 14.1549 3.875 12 3.875C9.84512 3.875 7.77849 4.73102 6.25476 6.25476C4.73102 7.77849 3.875 9.84512 3.875 12C3.875 14.1549 4.73102 16.2215 6.25476 17.7452C7.77849 19.269 9.84512 20.125 12 20.125C14.1549 20.125 16.2215 19.269 17.7452 17.7452C19.269 16.2215 20.125 14.1549 20.125 12ZM2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C9.34784 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12ZM8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75C11.0054 15.75 10.0516 15.3549 9.34835 14.6517C8.64509 13.9484 8.25 12.9946 8.25 12ZM12.9375 12C12.9375 11.7514 12.8387 11.5129 12.6629 11.3371C12.4871 11.1613 12.2486 11.0625 12 11.0625C11.7514 11.0625 11.5129 11.1613 11.3371 11.3371C11.1613 11.5129 11.0625 11.7514 11.0625 12C11.0625 12.2486 11.1613 12.4871 11.3371 12.6629C11.5129 12.8387 11.7514 12.9375 12 12.9375C12.2486 12.9375 12.4871 12.8387 12.6629 12.6629C12.8387 12.4871 12.9375 12.2486 12.9375 12Z'
        fill='currentColor'
      />
    </svg>
  );
}
