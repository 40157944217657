import { SVGProps } from 'react';

export default function DumbbellIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.4 4H6.225H8.975H9.8V4.85714V11.1429H14.2V4.85714V4H15.025H17.775H18.6V4.85714V6.57143H20.525H21.35V7.42857V11.1429H22.175H23V12.8571H22.175H21.35V16.5714V17.4286H20.525H18.6V19.1429V20H17.775H15.025H14.2V19.1429V12.8571H9.8V19.1429V20H8.975H6.225H5.4V19.1429V17.4286H3.475H2.65V16.5714V12.8571H1.825H1V11.1429H1.825H2.65V7.42857V6.57143H3.475H5.4V4.85714V4ZM7.05 6.57143V7.42857V16.5714V17.4286V18.2857H8.15V5.71429H7.05V6.57143ZM5.4 8.28571H4.3V15.7143H5.4V8.28571ZM16.95 16.5714V7.42857V6.57143V5.71429H15.85V18.2857H16.95V17.4286V16.5714ZM19.7 15.7143V8.28571H18.6V15.7143H19.7Z'
        fill='currentColor'
      />
    </svg>
  );
}
