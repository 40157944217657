// General
import { FC, SVGProps } from "react"

const TwitterIcon: FC<SVGProps<SVGSVGElement>>  = (props) => (
  <svg id='Ebene_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 15' {...props}>
    <g>
      <path fill='currentColor'
            d='M17.05,3.73c.01,.16,.01,.33,.01,.49,0,5-3.92,10.78-11.09,10.78h0c-2.12,0-4.19-.59-5.98-1.7,.31,.04,.62,.05,.93,.05,1.76,0,3.46-.57,4.84-1.62-1.67-.03-3.13-1.09-3.64-2.63,.58,.11,1.19,.09,1.76-.07-1.82-.36-3.13-1.91-3.13-3.71v-.05c.54,.29,1.15,.46,1.77,.47C.82,4.64,.29,2.42,1.32,.69c1.98,2.37,4.9,3.8,8.03,3.96-.31-1.32,.11-2.69,1.13-3.62,1.57-1.43,4.04-1.36,5.52,.16,.87-.17,1.71-.48,2.48-.92-.29,.88-.9,1.62-1.71,2.09,.77-.09,1.53-.29,2.24-.6-.52,.76-1.18,1.43-1.95,1.96Z' />
    </g>
  </svg>
);

export default TwitterIcon;