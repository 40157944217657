import { useApi } from '../ApiProvider';
import { IClaimCoinsResponse, IStakeTokensResponse, ITrainingStatus } from '../../types/stake';

const BASE_PATH = '/v2/staking/staking';

export const useTrainingApi = () => {
  const apiInstance = useApi();

  const claimCoins = async () => {
    return (await apiInstance.post<IClaimCoinsResponse>(`${BASE_PATH}/claim`)).data;
  };

  const stakeTokens = async () => {
    return (await apiInstance.post<IStakeTokensResponse>(`${BASE_PATH}/stake`)).data;
  };

  const getTrainingStatus = async () => {
    return (await apiInstance.get<ITrainingStatus>(`${BASE_PATH}/status`)).data;
  };

  return {
    claimCoins,
    stakeTokens,
    getTrainingStatus,
  };
};
