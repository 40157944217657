import styled from 'styled-components';
import { DetailPageSubHeadline } from './DetailPageStyledComponents';
import { Button } from '../../../common/Button';

const Wrapper = styled.div`
  display: grid;
  gap: 0.75rem;
  margin-block-end: 2rem;
  --size: 2.75rem;
`;

const SelectWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const AmountButton = styled(Button)`
  display: grid;
  place-content: center;
  padding: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid var(--color-text-default);
  background-color: var(--color-text-highlight);
  font-size: 1.5rem;
  width: var(--size);
  height: var(--size);

  &:disabled {
    opacity: 0.3;
  }
`;

const Amount = styled.div<{ disabled?: boolean }>`
  display: grid;
  place-content: center;
  padding: 0.625rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-text-default);
  font-size: 1.25rem;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  width: var(--size);
  height: var(--size);
`;

interface IDetailPageAmountSelectProps {
  amount: number;
  setAmount: (amount: number) => void;
  limit?: number;
  disabled?: boolean;
}

export default function DetailPageAmountSelect(props: IDetailPageAmountSelectProps) {
  const { amount, setAmount, limit, disabled } = props;

  return (
    <Wrapper>
      <DetailPageSubHeadline>Raffle Tickets</DetailPageSubHeadline>

      <SelectWrapper>
        <AmountButton onClick={() => setAmount(amount - 1)} disabled={disabled || amount === 1} type={'button'}>
          -
        </AmountButton>

        <Amount disabled={disabled}>{amount}</Amount>

        <AmountButton onClick={() => setAmount(amount + 1)} disabled={disabled || amount === limit} type={'button'}>
          +
        </AmountButton>
      </SelectWrapper>
    </Wrapper>
  );
}
