import styled from 'styled-components';
import { ILink } from '../../../types/link';
import { FC } from 'react';
import { Button } from '../../common/Button';
import { AppHeadline } from '../Container';

const Headline = styled(AppHeadline)`
  margin-bottom: 1rem;
`;

const Text = styled.p`
  margin-bottom: 2rem;
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

export interface StatusSectionProps {
  headline: string;
  text: string;
  link: ILink;
}

export const StatusSection: FC<StatusSectionProps> = ({ headline, text, link }) => (
  <>
    <Headline>{headline}</Headline>
    <Text>{text}</Text>
    <StyledButton {...link} theme={'tertiary'}>
      {link.title}
    </StyledButton>
  </>
);
