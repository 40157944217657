import styled from 'styled-components';
import { ToastContentProps } from 'react-toastify';
import Toast, { ToastContent, ToastHeadline } from './Toast';
import { TNotification } from '../../../types/page';

export const ErrorHeadline = styled(ToastHeadline)`
  color: var(--color-signal-red);
`;

interface IErrorToastProps extends TNotification, Partial<ToastContentProps> {
  //
}

export default function ErrorToast({ headline, text, closeToast }: IErrorToastProps) {
  return (
    <Toast closeToast={closeToast} type={'error'}>
      <ErrorHeadline>{headline}</ErrorHeadline>
      <ToastContent content={text} />
    </Toast>
  );
}
