// General
import { SVGProps } from 'react';

export default function AppleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.8773 13.0404C17.8688 11.4837 18.5744 10.3087 20.0027 9.44336C19.2035 8.3023 17.9963 7.67451 16.4022 7.5515C14.8932 7.43273 13.2439 8.42956 12.6403 8.42956C12.0027 8.42956 10.5404 7.59392 9.39267 7.59392C7.02072 7.63209 4.5 9.48154 4.5 13.2441C4.5 14.3554 4.70404 15.5035 5.11211 16.6884C5.65622 18.2452 7.62009 22.0628 9.66897 21.9992C10.7402 21.9738 11.4968 21.2399 12.8911 21.2399C14.2428 21.2399 14.9442 21.9992 16.1387 21.9992C18.2046 21.9695 19.9814 18.4997 20.5 16.9387C17.7285 15.6364 17.8773 13.121 17.8773 13.0404ZM15.4713 6.07534C16.6318 4.70098 16.5255 3.44964 16.4915 3C15.4671 3.05939 14.2811 3.69566 13.6052 4.4804C12.8613 5.32029 12.4235 6.35954 12.517 7.53029C13.6265 7.61513 14.6382 7.04672 15.4713 6.07534Z'
        fill='currentColor'
      />
    </svg>
  );
}
